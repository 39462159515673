import { useContext, useEffect, useState } from "react";
import ConnectionContext from "./ConnectionContext";
import requester from "../components/rentals/requester";
import PouchDB from "pouchdb-browser";
import { v4 as uuidv4 } from "uuid"; // Import the UUID generator

/*  
    Descripcion para custom hook: 
    =============================>
    se necesita un custom hook que pueda recibir un request
    y que cada vez que se mande a llamar se guarde en una cola de peticiones en un estado, 
    y que cuando detecte si isOnline de ConnectionContext que detecta la conexion a internet
    se mande a llamar una funcion que ejecuta las peticiones en la cola de peticiones 1 por 1
    esto se renderiza sobre el index.js para que pueda ejecutarse en todas las vistas mientras
    exista algo en la cola de peticiones y este online

    update - 8/22/23
    =============================>
    - Logica para las peticiones fallidas.
    - Sistema de notificaciones para peticiones fallidas (floatingNotification) (in progress).

    update - 8/24/23
    =============================>
    - Nueva logica para las pending request desde el execute queue.
*/

export default function RequestQueueHandler() {
  const isOnline = useContext(ConnectionContext);
  const [requestQueue, setRequestQueue] = useState([]);
  const [notificationRequests, setNotificationRequests] = useState([]);

  const db = new PouchDB("requests");

  useEffect(() => {
    if (isOnline) executeQueue();
  }, [isOnline]);

  const executeQueue = async () => {
    //verify if on the localdb exists pending requests
    const pendingRequests = await db.allDocs({ include_docs: true });

    if (pendingRequests.total_rows > 0) {
      //if exists, execute them
      pendingRequests.rows.forEach(async (request) => {
        const { url, method, body } = request.doc;
        setNotificationRequests([...notificationRequests, request.doc]);

        try {
          const response = await requester.dynamicRequest(url, method, body);
          console.log("response", response);
          await db.remove(request.doc);
        } catch (error) {}
      });
    }
  };

  const addRequestToQueue = async (request) => {
    const uniqueId = uuidv4();
    await db.put({ ...request, _id: uniqueId });
    setRequestQueue([...requestQueue, request]);
  };

  return { addRequestToQueue, notificationRequests };
}
