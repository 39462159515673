import { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import LoadingSpinner from "../loadingSpinner/Spinner";
import Scanner from "../barcodeScanner/Scanner";

export default function DriversTable({
  columns,
  data,
  loading,
  scanComponent,
  needSearch,
  needBanner,
  bannerText,
}) {
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const result = data?.filter((mydata) => {
      const valuesToFilter = ["internalId", ...Object.keys(mydata)];
      return valuesToFilter.some((key) => {
        const value = mydata[key];
        const stringValue =
          typeof value === "number" ? value.toString() : value;
        return (
          typeof stringValue === "string" &&
          stringValue.toLowerCase().match(search.toLowerCase())
        );
      });
    });

    setFilteredData(result);
  }, [data, search]);

  const getBarcodeNumber = (barcodeNumber) => {
    setSearch(barcodeNumber);
  };

  return (
    <div className="base-margin">
      <div className="table-container" style={{ padding: "1rem" }}>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <DataTable
            className="dataTable"
            pagination
            paginationPerPage={4}
            columns={columns}
            theme="solarized"
            highlightOnHover
            selectedRows
            data={filteredData}
            fixedHeader
            fixedHeaderScrollHeight="500px"
            subHeader
            subHeaderComponent={
              <>
                {needBanner && (
                  <div
                    className="banner"
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginBottom: needSearch ? "1rem" : "0",
                    }}
                  >
                    {bannerText}
                  </div>
                )}

                <div
                  style={{
                    display: "flex",
                    width: "100%",
                  }}
                >
                  {needSearch && (
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  )}

                  {scanComponent && <Scanner onScan={getBarcodeNumber} />}
                </div>
              </>
            }
            progressPending={false}
            subHeaderAlign="left"
          />
        )}
      </div>
    </div>
  );
}
