import { IoMdArrowRoundBack } from "react-icons/io";
import { NavLink } from "react-router-dom";
import "./FloatingButton.css";

export default function FloatingButton({ url, logout }) {
  const handleOnClick = () => {
    if (logout) {
      let consumer = JSON.parse(localStorage.getItem("consumer"));
      let netSuiteInstance = consumer.netsuiteInstance;
      let encodedInstance = btoa(netSuiteInstance);
      let redirectUrl = `https://www.dmcprecisionerp.com/?id=${encodedInstance}`;
      window.location.href = redirectUrl;
      localStorage.removeItem("consumer");
    }
  };

  if (url) {
    return (
      <div className="floating-container">
        <NavLink to={url} className="floating-button" onClick={handleOnClick}>
          <div className="icon-container">
            <IoMdArrowRoundBack fontSize={25} />
          </div>
        </NavLink>
      </div>
    );
  } else {
    return null;
  }
}
