import { localDB } from "../dbConfig";

async function syncAndInsertOrUpdateDB(res, type, employeeId) {
  // Obtener la lista de documentos existentes en la base de datos local
  const existingDocs = await localDB.allDocs({ include_docs: true });

  // Crear un objeto para almacenar los _id de los documentos existentes
  const existingDocIds = {};

  for (const row of existingDocs.rows) {
    const doc = row.doc;
    existingDocIds[doc._id] = true;

    if (doc.type === type && doc.data.employeeId === employeeId) {
      if (!res.some((item) => item.uuid === doc._id)) {
        await localDB.remove(doc);
      }
    }
  }

  // Insertar o actualizar documentos en la base de datos local
  const docsToInsertOrUpdate = res.map((item) => ({
    _id: item.uuid,
    type,
    data: item,
  }));

  for (const doc of docsToInsertOrUpdate) {
    // Verificar si el documento ya existe en la base de datos local
    if (existingDocIds[doc._id]) {
      // Si existe, obtener el documento actual para comparar la fecha de actualización
      const existingDoc = await localDB.get(doc._id);

      // Comparar las fechas de actualización
      if (doc.data.updated_at > existingDoc.data.updated_at) {
        // Si la fecha de actualización es más reciente, actualizar el documento local
        await localDB.put(doc);
      }
    } else {
      // Si no existe, insertar un nuevo documento en la base de datos local
      await localDB.put(doc);
    }
  }
}

// Hook personalizado que encapsula la función syncAndInsertOrUpdateDB
export function useSyncAndInsertOrUpdateDB() {
  async function syncData(res, type, employeeId) {
    await syncAndInsertOrUpdateDB(res, type, employeeId);
  }

  return {
    syncData,
  };
}
