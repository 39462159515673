import { useState, useEffect, useContext } from "react";
import DriversTable from "../../components/driversTable/DriversTable";
import FloatingButton from "../../components/floatingButton/FloatingButton";
import Header from "../../components/header/header";
import { FaArrowRight } from "react-icons/fa";
import requester from "../../components/rentals/requester";
import { Link } from "react-router-dom";
import ConnectionContext from "../../Hooks/ConnectionContext";
import { localDB, remoteDB } from "../../dbConfig";
import useToast from "../../Hooks/UseToast";
import { useSyncAndInsertOrUpdateDB } from "../../Hooks/SyncDatabase";

export default function DriverPageDelivery() {
  const [delivery, setDelivery] = useState([]);
  const [loadingSpinner, setLoadingSpinner] = useState(true);
  const { handleCreateToast } = useToast();
  const { syncData } = useSyncAndInsertOrUpdateDB();

  let consumer = JSON.parse(localStorage.getItem("consumer"));
  const employeeId = consumer?.employeeId;
  const envUrl = process.env.REACT_APP_MY_KEY_SERVER; //REACT_APP_MY_KEY_LOCAL - REACT_APP_MY_KEY_SERVER;
  const netsuiteInstance = consumer.netsuiteInstance; //consumer.netsuiteInstance; - "TSTDRV2359357";
  const isOnline = useContext(ConnectionContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isOnline) {
          // Si está en línea, realiza la solicitud normalmente
          requester
            .dynamicRequest(`${envUrl}/postObtainDeliveries`, "POST", {
              companyId: netsuiteInstance,
              endpoint: "postObtainDeliveries",
              type: "list",
              deliveryId: employeeId,
            })
            .then((res) => {
              (async () => {
                if (res.results === undefined) {
                  setLoadingSpinner(false);
                  return;
                }

                const infoWithUuid = res.results.map((info) => ({
                  ...info,
                  uuid: info.deliveryId.toString(),
                  userId: employeeId,
                }));

                setDelivery(infoWithUuid);
                await syncData(infoWithUuid, "delivery", employeeId);
                setLoadingSpinner(false);
                await localDB.sync(remoteDB, { live: true, retry: true });
              })();
            });
        } else {
          handleCreateToast(
            "info",
            "You are offline, the last data saved in the local DB will be loaded.",
            5000
          );
          // Si está sin conexión, carga los datos desde la base de datos local
          const result = await localDB.allDocs({ include_docs: true });
          const docs = result.rows.map((row) => row.doc);

          const filteredDeliveries = docs.filter(
            (doc) => doc.type === "delivery" && doc.data.userId === employeeId
          );

          const deliveriesToShow = filteredDeliveries.map((doc) => ({
            deliveryNumber: doc.data.deliveryNumber,
            fullNameEmployee: doc.data.fullNameEmployee,
            deliveryDate: doc.data.deliveryDate,
            carrier: doc.data.carrier,
          }));

          setLoadingSpinner(false);
          setDelivery(deliveriesToShow);
        }
      } catch (error) {
        setLoadingSpinner(false);
      }
    };
    fetchData();
  }, [isOnline]);

  const columns = [
    {
      name: "Delivery Number",
      selector: (row) => row.deliveryNumber,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.fullNameEmployee,
      sortable: true,
    },
    {
      name: "Delivery Day",
      selector: (row) => row.deliveryDate,
      sortable: true,
    },
    {
      name: "Carrier",
      selector: (row) => row.carrier,
      sortable: true,
    },
    {
      button: true,
      name: "",
      selector: (row) => row.action,
      cell: (row) => (
        <Link
          to={`/driverDelivery/PickUpReturn/${row.deliveryNumber}`}
          state={{
            deliveryNumber: row.deliveryNumber,
          }}
        >
          <button type="button" className="available_button">
            <FaArrowRight
              fontSize={"1rem"}
              style={{
                display: "flex",
              }}
            />
          </button>
        </Link>
      ),
    },
  ];

  return (
    <>
      <Header />
      <DriversTable
        columns={columns}
        data={delivery}
        loading={loadingSpinner}
        needSearch={true}
        needBanner={true}
        bannerText={"Deliveries"}
      />
      <FloatingButton url={"/home"} logout={false} />
    </>
  );
}
