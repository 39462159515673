import { useEffect, useState, useContext } from "react";
import DriversTable from "../../components/driversTable/DriversTable";
import FloatingButton from "../../components/floatingButton/FloatingButton";
import Header from "../../components/header/header";
import { FaArrowRight } from "react-icons/fa";
import requester from "../../components/rentals/requester";
import { Link, useParams } from "react-router-dom";
import { localDB, remoteDB } from "../../dbConfig";
import useToast from "../../Hooks/UseToast";
import ConnectionContext from "../../Hooks/ConnectionContext";
import { useSyncAndInsertOrUpdateDB } from "../../Hooks/SyncDatabase";

export default function DriverPagePickRet() {
  const [pickupRet, setPickRet] = useState([]);
  const [loadingSpinner, setLoadingSpinner] = useState(true);
  const { handleCreateToast } = useToast();
  const { syncData } = useSyncAndInsertOrUpdateDB();
  const { id } = useParams();
  const isOnline = useContext(ConnectionContext);
  const consumer = JSON.parse(localStorage.getItem("consumer"));
  const netsuiteInstance = consumer?.netsuiteInstance;
  const employeeId = consumer?.employeeId;
  const envUrl = process.env.REACT_APP_MY_KEY_SERVER; //REACT_APP_MY_KEY_LOCAL - REACT_APP_MY_KEY_SERVER;

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!isOnline) {
          handleCreateToast(
            "info",
            "You are offline, the last data saved in the local DB will be loaded.",
            5000
          );

          const result = await localDB.allDocs({ include_docs: true });
          const docs = result.rows.map((row) => row.doc);

          const filteredData = docs.filter(
            (doc) => doc.type === "pickret" && doc.data.userId === employeeId
          );

          const mapData = filteredData.map((doc) => doc.data);

          setPickRet(mapData);
          setLoadingSpinner(false);

          return;
        }

        // Fetch reasons first
        const reasonsResponse = await requester.dynamicRequest(
          `${envUrl}/reschedule/getReasons`,
          "POST",
          {
            companyId: netsuiteInstance,
            endpoint: "scheduleURL",
          }
        );
        const reasons = reasonsResponse; // Adjust this based on the actual response structure

        // Fetch pickUpReturns data
        const pickUpReturnsResponse = await requester.dynamicRequest(
          `${envUrl}/getPickUpReturnsByUserId`,
          "POST",
          {
            companyId: netsuiteInstance,
            deliverynumber: id,
            endpoint: "rentalDispatch",
          }
        );

        const infoWithUuid = Object.entries(pickUpReturnsResponse).map(
          ([key, value]) => ({
            ...value,
            pickret: key,
            uuid: key,
            userId: employeeId,
            reasons,
          })
        );

        setPickRet(infoWithUuid);

        await syncData(infoWithUuid, "pickret", employeeId);
        setLoadingSpinner(false);
        await localDB.sync(remoteDB, { live: true, retry: true });
      } catch (error) {
        setLoadingSpinner(false);
      }
    };

    fetchData();
  }, [isOnline]);

  const columns = [
    {
      name: "Delivery",
      selector: (row) => row.deliveryName,
      sortable: true,
    },
    {
      name: "Customer",
      selector: (row) => row.customer,
      sortable: true,
    },
    {
      name: "Rental Reservation",
      selector: (row) => row.reservation,
      sortable: true,
    },
    {
      name: "ID",
      selector: (row) => row.reservationId,
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => row.deliveryAddress,
      sortable: true,
      cell: (row) => (
        <div className="address_column">{row.deliveryAddress}</div>
      ),
    },
    {
      button: true,
      name: "Action",
      selector: (row) => row.action,
      cell: (row) => (
        <Link
          to={`/driverDelivery/PickUpReturn/driverMarkComp/${id}`}
          state={{
            customer: row.customer,
            customerId: row.customerId,
            deliveryAddress: row.deliveryAddress,
            deliveryDate: row.deliveryDate,
            deliveryId: row.deliveryId,
            deliveryName: row.deliveryName,
            deliveryNumber: row.deliveryNumber,
            deliveryStatus: row.deliveryStatus,
            driverName: row.driverName,
            driverPhone: row.driverPhone,
            lines: row.lines,
            recordType: row.recordType,
            reservation: row.reservation,
            reservationId: row.reservationId,
            reasons: row.reasons,
          }}
        >
          <button type="button" className="available_button">
            <FaArrowRight
              fontSize={"1rem"}
              style={{
                display: "flex",
              }}
            />
          </button>
        </Link>
      ),
    },
  ];

  return (
    <>
      <Header />

      <DriversTable
        columns={columns}
        data={pickupRet}
        loading={loadingSpinner}
        scanComponent={true}
        needSearch={true}
        needBanner={true}
        bannerText={"Pick Up / Return"}
      />

      <FloatingButton url={"/home/driverDelivery"} logout={false} />
    </>
  );
}
