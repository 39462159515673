import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import requester from "../../components/rentals/requester";
import Swal from "sweetalert2";
import "./dropoff.css";
import LoadingSpinner from "../../components/loadingSpinner/Spinner";

const CustomModal = (props) => {
  const { title } = props;
  let rentalsArray = [];
  let deliveryLines = [];
  const [rentals, setRentals] = useState([]);
  const [loading, setLoading] = useState(false);
  const columns = [
    {
      name: "Delivery Number",
      selector: (row) => row.deliveryNumber,
      sortable: true,
    },
    {
      name: "Delivery Day",
      selector: (row) => row.deliveryDay,
      sortable: true,
    },
    {
      name: "Driver Name",
      selector: (row) => row.driverName,
      sortable: true,
    },
    {
      name: "Driver Phone Number",
      selector: (row) => row.driverPhoneNumber,
      sortable: true,
    },
  ];
  let consumer = JSON.parse(localStorage.getItem("consumer"));

  useEffect(() => {
    const modal = document.getElementById("exampleModal");
    modal.addEventListener("shown.bs.modal", function () {
      requester
        .dynamicRequest(
          `${process.env.REACT_APP_MY_KEY_SERVER}/searchDeliveryNumber`,
          "POST",
          {
            companyId: consumer.netsuiteInstance,
            endpoint: "getDeliverySearchUrl",
            date: "",
            deliverynumber: "",
          }
        )
        .then((res) => {
          if (res.status === 400) {
            setLoading(false);
            Swal.fire({
              title: res.message,
              icon: "warning",
              confirmButtonText: "Ok",
              timer: 4000,
            });
            return;
          }

          res.forEach((item) => {
            rentalsArray.push({
              deliverid: item.deliverid,
              deliveryNumber: item.deliverynumber,
              deliveryDay: item.deliveryday,
              driverName: item.drivername,
              driverPhoneNumber: item.driverphone,
            });
          });

          setRentals(rentalsArray);

          setTimeout(() => {
            const table = document.getElementsByClassName("rdt_TableBody");
            table[0].childNodes.forEach((item) => {
              item.childNodes[0].childNodes[0].checked = false;
            });
          }, 50);
        });
    });
  }, []);

  const handleCloseModalSearch = () => {
    rentalsArray = [];
    setRentals(rentalsArray);
    const date = document.querySelector("input[type=date]");
    date.value = "";
  };

  const handleSelectRow = (state) => {
    deliveryLines = state.selectedRows;
  };

  const handleSearchDelivery = () => {
    const date = document
      .querySelector("input[type=date]")
      .value.replace(/-/g, "/");

    const dateArray = date.split("/");
    let newDate = `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
    let driverNameSearch = document.getElementById("driverNameSearch").value;

    if (newDate === "undefined/undefined/") newDate = "";
    if (driverNameSearch === "") driverNameSearch = "";

    setLoading(true);
    requester
      .dynamicRequest(
        `${process.env.REACT_APP_MY_KEY_SERVER}/searchDeliveryNumber`,
        "POST",
        {
          companyId: consumer.netsuiteInstance,
          endpoint: "getDeliverySearchUrl",
          date: newDate,
          deliverynumber: driverNameSearch,
        }
      )
      .then((res) => {
        if (res.status === 400) {
          setLoading(false);
          Swal.fire({
            title: res.message,
            icon: "warning",
            confirmButtonText: "Ok",
            timer: 4000,
          });
          return;
        }

        res.forEach((item) => {
          rentalsArray.push({
            deliverid: item.deliverid,
            deliveryNumber: item.deliverynumber,
            deliveryDay: item.deliveryday,
            driverName: item.drivername,
            driverPhoneNumber: item.driverphone,
          });
        });
        setRentals(rentalsArray);
        setLoading(false);
      });
  };

  const sendInfo = () => {
    if (deliveryLines.length === 0) {
      const Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: "warning",
        html: `<h5>Please select a delivery</h5>`,
      });
      return;
    }

    const closebtn = document.getElementById("close-btn");
    closebtn.click();
    props.onSearch(deliveryLines);
    rentalsArray = [];
    setRentals(rentalsArray);
  };

  return (
    <>
      <button
        type="button"
        className="secondary-button"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Delivery Search
      </button>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        {loading && <LoadingSpinner />}
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {title}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="close-btn"
                onClick={() => {
                  handleCloseModalSearch();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <section className="flex-body">
                <div className="input-group">
                  <span className="input-group-text" id="basic-addon1">
                    Deliver Date
                  </span>
                  <input type="date" className="form-control" />
                </div>
                <div className="input-group">
                  <span className="input-group-text">Driver Name</span>
                  <input
                    type="text"
                    className="form-control"
                    id="driverNameSearch"
                  />
                </div>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => handleSearchDelivery()}
                >
                  Search
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => sendInfo()}
                >
                  Add Delivery
                </button>
              </section>
              <section className="table-container">
                <div className="banner">Deliveries</div>
                <DataTable
                  className="dataTable"
                  id="miDataTable"
                  pagination
                  columns={columns}
                  theme="solarized"
                  highlightOnHover
                  selectedRows
                  responsive
                  data={rentals}
                  fixedHeader
                  fixedHeaderScrollHeight="200px"
                  selectableRows
                  onSelectedRowsChange={(state) => handleSelectRow(state)}
                  selectableRowsNoSelectAll
                  selectableRowsHighlight
                  selectableRowsSingle
                />
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomModal;
