import { useState } from "react";
import "./OpportunityDetailModal.css";
import Modal from "react-responsive-modal";
import LoadingSpinner from "../loadingSpinner/Spinner";
import { IoCloseSharp } from "react-icons/io5";
import DriversTable from "../driversTable/DriversTable";
import requester from "../rentals/requester";

export default function OpportunityDetailModal({ opportunity, row, parentId }) {
  const [open, setOpen] = useState(false);
  const [spinner, setSpinner] = useState(true);
  const [lines, setLines] = useState([]);
  const [primaryInfo, setPrimaryInfo] = useState([
    {
      id: 1,
      label: "Check-In #",
      value: "",
    },
    {
      id: 2,
      label: "Company",
      value: "",
    },
    {
      id: 3,
      label: "Service Date",
      value: "",
    },
    {
      id: 4,
      label: "Location",
      value: "",
    },
    {
      id: 5,
      label: "Parent Opportunity",
      value: "",
    },
  ]);

  const [serviceInfo, setServiceInfo] = useState([
    {
      id: 1,
      label: "Job Status",
      value: "",
    },
    {
      id: 2,
      label: "Job Type",
      value: "",
    },
    {
      id: 3,
      label: "Technicians",
      value: "",
    },
    {
      id: 4,
      label: "Customer Serial",
      value: "",
    },
    {
      id: 5,
      label: "Customer Item",
      value: "",
    },
  ]);

  let consumer = JSON.parse(localStorage.getItem("consumer"));

  const envUrl = process.env.REACT_APP_MY_KEY_SERVER; //REACT_APP_MY_KEY_LOCAL - REACT_APP_MY_KEY_SERVER;
  const companyId = consumer.netsuiteInstance; //consumer.netsuiteInstance; - "TSTDRV2359357";

  const columns = [
    {
      name: "Item",
      selector: (row) => row.itemname,
      sortable: true,
    },
    {
      name: "Item Type",
      selector: (row) => row.itemtype,
      sortable: true,
    },
    {
      name: "Quantity",
      selector: (row) => row.quantity,
      sortable: true,
    },
    {
      name: "Service Rate",
      selector: (row) => row.rate,
      sortable: true,
    },
    {
      name: "Service Amount",
      selector: (row) => row.rateamount,
      sortable: true,
    },
  ];

  const onOpenModal = async () => {
    setOpen(true);
    setSpinner(true);

    const body = {
      companyId: companyId,
      endPoint: "obtainNetSuiteDataURL",
      typeOfConsult: 7,
      opportunityId: opportunity,
      isParent: false,
    };

    const details = await loadAndGetData("postObtainNetSuiteData", body);
    //parent details
    const {
      parentOpportunityJobTypeName,
      parentOpportunityStatusName,
      parentOpportunityCreatedFromName,
      parentOpportunityCustomerName,
      parentOpportunityServiceDate,
      parentOpportunityLocation,
      parentOpportunityTechnicianObject,
    } = details.responseService[0];

    //lines
    const lines = details.responseService[0].lineItems.map((line) => {
      return {
        itemname: line.itemname,
        itemtype: line.itemtype,
        quantity: line.quantity,
        rate: line.rate,
        rateamount: line.rateamount,
      };
    });
    setLines(lines);

    //information from the child
    const { serialNumberName, columnopportunityname, item } = row;

    //fill the primaryInfo
    const updatedPrimaryInfo = [...primaryInfo];
    updatedPrimaryInfo[0].value = columnopportunityname ?? ""; //child opp name
    updatedPrimaryInfo[1].value = parentOpportunityCustomerName ?? ""; // company
    updatedPrimaryInfo[2].value = parentOpportunityServiceDate ?? ""; // service date
    updatedPrimaryInfo[3].value = parentOpportunityLocation
      ? parentOpportunityLocation.text
      : ""; // location
    updatedPrimaryInfo[4].value = parentOpportunityCreatedFromName ?? ""; // parent opp name
    setPrimaryInfo(updatedPrimaryInfo);

    //fill the serviceInfo
    const updatedServiceInfo = [...serviceInfo];
    updatedServiceInfo[0].value = parentOpportunityStatusName ?? ""; // job status
    updatedServiceInfo[1].value = parentOpportunityJobTypeName ?? ""; // job type
    updatedServiceInfo[2].value = (
      parentOpportunityTechnicianObject?.map(
        (technician) => technician.technician
      ) ?? []
    ).join(", "); // technicians
    updatedServiceInfo[3].value = serialNumberName ?? ""; // customer serial
    updatedServiceInfo[4].value = item ?? ""; // customer item
    setServiceInfo(updatedServiceInfo);
  };

  const onCloseModal = () => {
    //clear all the values
    setPrimaryInfo([
      {
        id: 1,
        label: "Check-In #",
        value: "",
      },
      {
        id: 2,
        label: "Company",
        value: "",
      },
      {
        id: 3,
        label: "Service Date",
        value: "",
      },
      {
        id: 4,
        label: "Location",
        value: "",
      },
      {
        id: 5,
        label: "Parent Opportunity",
        value: "",
      },
    ]);

    setServiceInfo([
      {
        id: 1,
        label: "Job Status",
        value: "",
      },
      {
        id: 2,
        label: "Job Type",
        value: "",
      },
      {
        id: 3,
        label: "Technician",
        value: "",
      },
      {
        id: 4,
        label: "Customer Serial",
        value: "",
      },
      {
        id: 5,
        label: "Customer Item",
        value: "",
      },
    ]);

    setLines([]);
    setOpen(false);
  };

  const loadAndGetData = async (endpoint, body) => {
    try {
      const res = await requester.dynamicRequest(
        `${envUrl}/${endpoint}`,
        "POST",
        body
      );
      setSpinner(false);
      return res;
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      <p
        style={{
          color: "#007bff",
          cursor: "pointer",
          borderBottom: "1px solid #007bff",
          margin: 0,
        }}
        onClick={onOpenModal}
      >
        {row.columnopportunityname}
      </p>

      <Modal
        open={open}
        center
        closeOnOverlayClick={false}
        classNames={{ modal: "custom-modal" }}
      >
        {spinner && <LoadingSpinner />}
        <div className="header_modal">
          <h3
            style={{
              marginRight: "1rem",
            }}
          >
            {row.columnopportunityname} - Details
          </h3>
          <button
            onClick={onCloseModal}
            style={{
              cursor: "pointer",
            }}
          >
            <IoCloseSharp fontSize={25} />
          </button>
        </div>

        <div
          className="modal_body"
          style={{
            marginTop: "1rem",
          }}
        >
          <h4 className="title_banner">Primary Information</h4>

          <div className="container_grid_info_opp">
            {primaryInfo.map((label) => (
              <div className="flex_info_opp" key={label.id}>
                <label>{label.label}</label>
                <strong
                  style={{
                    wordWrap: "break-word",
                  }}
                >
                  {label.value}
                </strong>
              </div>
            ))}
          </div>

          <h4 className="title_banner">Service Information</h4>

          <div className="container_grid_info_opp">
            {serviceInfo.map((label) => (
              <div className="flex_info_opp" key={label.id}>
                <label>{label.label}</label>
                <strong
                  style={{
                    wordWrap: "break-word",
                  }}
                >
                  {label.value}
                </strong>
              </div>
            ))}
          </div>

          <DriversTable
            columns={columns}
            data={lines}
            needBanner={true}
            bannerText={"Service Rental Information"}
          />
        </div>
      </Modal>
    </>
  );
}
