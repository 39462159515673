import { useState, useRef, useEffect, useContext } from "react";
import { ErrorBoundary } from "react-error-boundary";
import Header from "../../components/header/header";
import DataTable from "react-data-table-component";
import SignaturePad from "react-signature-canvas";
import LoadingSpinner from "../../components/loadingSpinner/Spinner";
import ReactWebCam from "../../components/ReactWebCam/ReactWebCam";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment/moment";
import Swal from "sweetalert2";
import requester from "../../components/rentals/requester";
import ConnectionContext from "../../Hooks/ConnectionContext";
import useToast from "../../Hooks/UseToast";
import RequestQueueHandler from "../../Hooks/RequestQueueHandler";
import FloatingButton from "../../components/floatingButton/FloatingButton";

const BoundaryComponent = () => {
  const [saved, setSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [reasons, setReasons] = useState([]);
  const pickupReturn = useLocation();
  const { id } = useParams();
  const isOnline = useContext(ConnectionContext);
  const { handleCreateToast } = useToast();
  const { addRequestToQueue } = RequestQueueHandler();

  let signPad = useRef({});
  let signPad64 = "";
  let consumer = JSON.parse(localStorage.getItem("consumer"));
  const netsuiteInstance = consumer?.netsuiteInstance;
  const netsuiteEmployeeId = consumer?.employeeId;
  const userEmail = consumer?.email;
  const envUrl = process.env.REACT_APP_MY_KEY_SERVER; //REACT_APP_MY_KEY_LOCAL - REACT_APP_MY_KEY_SERVER;

  const columns = [
    {
      name: "Item ID",
      selector: (row) => row.itemid,
      sortable: true,
    },
    {
      name: "Hardware record",
      selector: (row) => row.hardwarerecord,
      sortable: true,
    },
    {
      name: "Quantity",
      selector: (row) => row.quantity,
      sortable: true,
    },
    {
      name: "Serial",
      selector: (row) => row.serial,
      sortable: true,
    },
    {
      name: "Rental Location",
      selector: (row) => row.rentallocationName,
      sortable: true,
    },
    {
      name: "Return Location",
      selector: (row) => row.returnlocationName,
      sortable: true,
    },
  ];

  const checkListItems = [
    {
      id: 1,
      description: "All items have been inspected for damage",
    },
    {
      id: 2,
      description: "All batteries have been removed from items",
    },
    {
      id: 3,
      description: "Customer signed return inspection form",
    },
  ];

  useEffect(() => {
    setReasons(pickupReturn.state.reasons);

    document.getElementById("dateDeliveryDriver").value = moment(
      new Date(pickupReturn.state.deliveryDate)
    ).format("YYYY-MM-DD");
  }, []);

  const clear = () => {
    signPad.current.clear();
    var base64 = document.getElementById("base64_signature");
    base64.value = "";
    setSaved(false);
  };

  const save = () => {
    if (!signPad.current.isEmpty()) {
      setSaved(true);
      signPad64 = signPad.current.toDataURL();
      var base64 = document.getElementById("base64_signature");
      base64.value = signPad64;
    }
  };

  const receivePhotos = (photos) => {
    let convertedPhotos = photos.map((photo) => {
      return new Promise((resolve, _reject) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          const pngBase64 = canvas.toDataURL("image/png");
          resolve(pngBase64);
        };
        img.src = photo;
      });
    });

    Promise.all(convertedPhotos)
      .then((pngImages) => {
        setImages(pngImages);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const markAsComplete = () => {
    let signature = document.getElementById("base64_signature").value;
    if (!signature) {
      Swal.fire({
        icon: "warning",
        title: "Please add and save a signature!",
        timer: 2000,
      });
      return;
    }

    setLoading(true);

    const {
      deliveryName,
      deliveryId,
      customerId,
      lines,
      recordType,
      reservationId,
    } = pickupReturn.state;

    //get all the checkboxes and labels
    const htmlCheckboxes = document.querySelectorAll("input#checklistItem");
    const htmlLabelCheckboxes = document.querySelectorAll(
      "label#checklistItem"
    );
    const checklist_items = [];
    htmlCheckboxes.forEach((checkbox, index) => {
      checklist_items.push({
        checked: checkbox.checked,
        item: htmlLabelCheckboxes[index].innerText,
      });
    });

    const body = {
      companyId: netsuiteInstance,
      endpoint: "rentalDispatch",
      deliveryRecord: deliveryId,
      customer: customerId,
      imagesJson: images,
      returnLocation: lines[0].returnlocation,
      rentalLocation: lines[0].rentallocation,
      name: deliveryName,
      reservationId: reservationId,
      deliveryLines: lines,
      recordType: recordType,
      signature: document.getElementById("base64_signature").value,
      buildhtml: { checklist_items },
      email: userEmail,
    };

    // si esta offline solo debo mandar la request no ejecutarla
    // if (!isOnline) {
    //   addRequestToQueue({
    //     url: `${envUrl}/postMarkAsComplete`,
    //     method: "POST",
    //     body,
    //     referenceParameter: deliveryName,
    //     date: moment(new Date()).format("YYYY-MM-DD"),
    //     id: uuidv4(),
    //   });

    //   handleCreateToast(
    //     "info",
    //     "The delivery will be created when you are online",
    //     "3000"
    //   );
    //   setLoading(false);
    //   return;
    // }
    // return;

    requester
      .dynamicRequest(`${envUrl}/postMarkAsComplete`, "POST", body)
      .then((res) => {
        if (!res) {
          setLoading(false);
          Swal.fire({
            title: "Error!",
            text: "Delivery could not be created!",
            icon: "error",
            confirmButtonText: "Ok",
            timer: 5000,
          });
          return;
        }

        setLoading(false);
        Swal.fire({
          title: "Success!",
          text: "You will be redirected to the delivery page in a moment!",
          icon: "success",
          confirmButtonText: "Ok",
          timer: 4000,
        });

        setTimeout(() => {
          window.location.href = `/driverDelivery/PickUpReturn/${id}`;
        }, 4000);
      });
  };

  const reschedule = () => {
    const { deliveryId, recordType } = pickupReturn.state;

    Swal.fire({
      title: "Please select a reason",
      input: "select",
      inputOptions: {
        "": "select a reason",
        ...reasons.reduce((acc, reason) => {
          acc[reason.name] = reason.name;
          return acc;
        }, {}),
      },
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Send",
      reverseButtons: true,
      preConfirm: (value) => {
        if (!value) {
          Swal.showValidationMessage("Please select a reason");
        }
        return value;
      },
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        //get the reason id
        const reasonId = reasons.find(
          (reason) => reason.name === result.value
        ).id;

        requester
          .dynamicRequest(`${envUrl}/reschedule`, "POST", {
            companyId: netsuiteInstance,
            endpoint: "scheduleURL",
            deliveryId: netsuiteEmployeeId,
            recordType: recordType,
            recordId: deliveryId,
            reasonId: reasonId,
          })
          .then((res) => {
            if (res.message === "ERROR") {
              Swal.fire({
                title: "Error!",
                text: `Could not send the reschedule: ${res.details}`,
                icon: "error",
                confirmButtonText: "Ok",
                timer: 5000,
              });
              setLoading(false);
              return;
            }

            Swal.fire({
              title: "Success!",
              text: "The email notification was sent successfully.",
              icon: "success",
              confirmButtonText: "Ok",
              timer: 4000,
            });

            setTimeout(() => {
              window.location.href = `/driverDelivery/PickUpReturn/${id}`;
            }, 4000);
            setLoading(false);
          })
          .catch((err) => {
            Swal.fire({
              title: "Error!",
              text: `Could not send the reschedule: ${err}`,
              icon: "error",
              confirmButtonText: "Ok",
              timer: 5000,
            });
            setLoading(false);
          });
      }
    });
  };

  return (
    <>
      {loading && <LoadingSpinner />}

      <Header />

      <section className="rental-header base-margin">
        <div>
          <h4 className="title">Driver Delivery</h4>
        </div>

        <div></div>
      </section>

      <div className="rental-container base-margin">
        <section className="info1">
          <div className="mb-3">
            <label className="form-label">Delivery Status</label>
            <input
              type="text"
              className="form-control"
              id="deliveryStatus"
              defaultValue={pickupReturn.state.deliveryStatus}
              disabled
            />
          </div>

          <label className="form-label">Delivery Time</label>
          <div className="mb-3">
            <input
              type="date"
              className="form-control"
              id="dateDeliveryDriver"
              disabled
            />
          </div>

          <div className="mb-3">
            <label className="form-label">Driver Name</label>
            <input
              type="text"
              className="form-control"
              id="driverName"
              defaultValue={pickupReturn.state.driverName}
              disabled
            />
          </div>
        </section>

        <section className="info2">
          <div className="mb-3">
            <label className="form-label">Driver Phone Number</label>
            <input
              type="text"
              className="form-control"
              id="driverNumber"
              defaultValue={pickupReturn.state.driverPhone}
              disabled
            />
          </div>

          <div className="mb-3">
            <label className="form-label">Rental Reservation</label>
            <input
              type="text"
              className="form-control"
              id="rentalReservation"
              defaultValue={pickupReturn.state.reservation}
              disabled
            />
          </div>

          <label className="form-label">Delivery Number</label>
          <div>
            <input
              type="text"
              className="form-control"
              id="deliveryNumber"
              defaultValue={pickupReturn.state.deliveryNumber}
              disabled
            />
          </div>

          <div className="mb-3 mt-3">
            <label className="form-label">Rental Customer</label>
            <input
              type="text"
              className="form-control"
              id="rentalCustomer"
              defaultValue={pickupReturn.state.customer}
              disabled
            />
          </div>
        </section>

        <section className="signature-container">
          <h5>Signature</h5>
          <div className="d-flex flex-column justify-content-center mx-auto mt-3">
            <div className="p-2 signature img-fluid img-thumbnail custom-size">
              <SignaturePad
                canvasProps={{
                  width: 500,
                  height: 200,
                }}
                ref={signPad}
              />
            </div>
            <p></p>
            <div className="d-flex justify-content-between">
              <div className="container">
                <button
                  type="button"
                  className="delete-button custom-size"
                  data-action="clear"
                  onClick={clear}
                >
                  Clear
                </button>
              </div>
              <div className="container">
                <button
                  type="button"
                  className="available_button custom-size custom-loader"
                  data-action="clear"
                  onClick={save}
                >
                  Save &nbsp;
                  {saved && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-check2 white font-weight-bold"
                    >
                      <path
                        fillRule="evenodd"
                        d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L6.5 10.793l6.646-6.647a.5.5 0 0 1 .708 0z"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>

          <div className="container">
            <input type="text" id="base64_signature" hidden></input>
          </div>
        </section>

        <section className="address">
          {/* Checklist section */}
          <div className="d-flex flex-column">
            <table className="table" id="checklistItem">
              <thead>
                <tr className="color-separate">
                  <th scope="col">Select</th>
                  <th scope="col">Checklist item </th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {checkListItems.map((item) => (
                  <tr key={item.id}>
                    <th scope="row">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="checklistItem"
                      />
                    </th>
                    <td>
                      <label
                        className="form-check-label"
                        htmlFor="checklistItem"
                        id="checklistItem"
                      >
                        {item.description}
                      </label>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="mb-3">
            <label className="form-label">Delivery Address Full</label>
            <textarea
              className="form-control"
              rows="3"
              id="address"
              defaultValue={pickupReturn.state.deliveryAddress}
              disabled
            ></textarea>
          </div>

          <div className="save-button-section">
            <button
              type="button"
              className="available_button"
              onClick={() => reschedule()}
            >
              Reschedule
            </button>
            <button
              type="button"
              className="available_button"
              onClick={() => markAsComplete()}
            >
              Mark As Complete
            </button>
          </div>
        </section>
      </div>

      <section className="table-container base-margin">
        <ReactWebCam sendBackPhotoArray={receivePhotos} />
      </section>

      <section className="table-container base-margin">
        <div className="banner">Delivery Lines</div>
        <DataTable
          className="dataTable"
          pagination
          columns={columns}
          theme="solarized"
          highlightOnHover
          selectedRows
          responsive
          data={pickupReturn.state.lines}
          fixedHeader
          fixedHeaderScrollHeight="500px"
        />
      </section>

      <FloatingButton
        url={`/driverDelivery/PickUpReturn/${id}`}
        logout={false}
      />
    </>
  );
};

const DriverMarkComp = () => {
  return (
    <ErrorBoundary>
      <BoundaryComponent />
    </ErrorBoundary>
  );
};

export default DriverMarkComp;
