import { useEffect, useState } from "react";
import "./login.css";
import requester from "../../components/rentals/requester";

const Login = () => {
  const [netsuiteInstance, setNetsuiteInstance] = useState("");

  useEffect(() => {
    // tomar instancia de netsuite del iframe
    function b64_to_utf8(str) {
      if (str === null) return "";
      return decodeURIComponent(escape(window.atob(str)));
    }
    const searchParams = new URLSearchParams(window.location.search);
    const encodedId = searchParams.get("id");
    const decodedId = b64_to_utf8(encodedId);
    setNetsuiteInstance(decodedId);
  }, []);
  function validateForm(value) {
    let myValue = value.target.value;
    if (myValue === "")
      return (value.target.nextElementSibling.style.display = "block");
    value.target.nextElementSibling.style.display = "none";
  }

  function customSnackbar(message, type) {
    let snackBar = document.getElementById("snackbar");
    snackBar.className = "show-bar";
    snackBar.innerHTML = message;
    snackBar.style.backgroundColor = type === 1 ? "#4CAF50" : "#bb2124";

    setTimeout(function () {
      snackBar.className = snackBar.className.replace("show-bar", "");
    }, 4000);
  }

  const handleLogin = () => {
    const loginBtnText = document.getElementById("loginBtnText");
    const spinner = document.querySelector(".spinner");
    const email = document.getElementById("email").value;
    const password = document.getElementById("password").value;

    if (
      validateForm({ target: document.getElementById("email") }) ||
      validateForm({ target: document.getElementById("password") })
    )
      return customSnackbar("Email/Password are required fields", 0);

    loginBtnText.style.display = "none";
    spinner.style.display = "inline-block";

    //TODO: descomentar para testing
    // window.location.href = "/home";
    // localStorage.setItem(
    //   "consumer",
    //   JSON.stringify({
    //     projectKey: "Prec-prod",
    //     expirationDate: "4/30/2024",
    //     netsuiteInstance: "TSTDRV2359357",
    //     role: "2",
    //     roleText: "Delivery User",
    //     employeeId: "4856",
    //   })
    // );
    // return;

    requester
      .dynamicRequest(`${process.env.REACT_APP_MY_KEY_SERVER}/login`, "POST", {
        companyId: netsuiteInstance,
        endpoint: "login",
        email: email,
        key: password,
      })
      .then((res) => {
        //comment for deploy
        if (res.status === "300") {
          loginBtnText.style.display = "block";
          spinner.style.display = "none";
          customSnackbar("The User is inactive", 0);
          return;
        } else if (res.status === "401") {
          loginBtnText.style.display = "block";
          spinner.style.display = "none";
          customSnackbar(`Invalid User/Password.`, 0);
          return;
        } else if (res.status === "101") {
          loginBtnText.style.display = "block";
          spinner.style.display = "none";
          customSnackbar("The employee does not exist in NetSuite", 0);
          return;
        } else {
          //verificar si expirationDate es superior a la fecha actual, si no es asi, no se puede loguear.
          let expirationDate = new Date(res.expirationDate);
          let currentDate = new Date();

          if (currentDate >= expirationDate) {
            loginBtnText.style.display = "block";
            spinner.style.display = "none";
            customSnackbar("Your Subscription has expired.", 0);
            return;
          }
          localStorage.setItem(
            "consumer",
            JSON.stringify({
              projectKey: res.projectKey,
              expirationDate: res.expirationDate,
              netsuiteInstance,
              role: res.userRole,
              roleText: res.userRoleText,
              employeeId: res.employeeId,
              email,
            })
          );

          window.location.href = "/home";
        }
      });
  };

  return (
    <div className="main-container">
      <section className="user-info">
        <img
          className="mobile-logo"
          src={process.env.PUBLIC_URL + "precisionicon.png"}
          alt="logo"
        />
        <header className="header">
          <h3>Welcome Back</h3>
          <p>Enter your credentials to login</p>
        </header>
        <div className="form-error">
          <input
            className="input-style"
            id="email"
            type="text"
            placeholder="Email"
            onKeyUp={(e) => validateForm(e)}
            onKeyUpCapture={(e) => {
              if (e.key === "Enter") handleLogin();
            }}
          />
          <small className="error">Email is a required field.</small>
        </div>

        <div className="form-error">
          <input
            className="input-style"
            id="password"
            type="password"
            placeholder="Password"
            onKeyUp={(e) => validateForm(e)}
            onKeyUpCapture={(e) => {
              if (e.key === "Enter") handleLogin();
            }}
          />
          <small className="error">Password is a required field.</small>
        </div>

        <button
          className="btn-style"
          type="button"
          onClick={() => handleLogin()}
        >
          <span id="loginBtnText">Log In</span>
          <div className="spinner"></div>
        </button>
      </section>
      <section className="logo-section">
        <img src={process.env.PUBLIC_URL + "precisionlogo.png"} alt="logo" />
      </section>

      <div id="snackbar"></div>
    </div>
  );
};

export default Login;
