import React from "react";
import { NavLink } from "react-router-dom";
import "./MosaicOption.css";

const MosaicOption = (props) => {
  return (
    <NavLink
      to={props.url}
      id="mosaicDiv"
      onClick={() => {
        if (props.title === "Logout") {
          let consumer = JSON.parse(localStorage.getItem("consumer"));
          let netSuiteInstance = consumer.netsuiteInstance;
          let encodedInstance = btoa(netSuiteInstance);
          let redirectUrl = `https://www.dmcprecisionerp.com/?id=${encodedInstance}`;
          window.location.href = redirectUrl;
          localStorage.removeItem("consumer");
        }
      }}
    >
      <div className="img_container">
        <img src={props.imageUrl} alt={props.title} />
      </div>
      <h1>{props.title}</h1>
    </NavLink>
  );
};

export default MosaicOption;
