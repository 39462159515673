import "./AccordionTable.css";

export default function AccordionTable({
  parentNumber,
  parentId,
  childs,
  onCreateOpportunity,
  onCreateChilds,
}) {
  return (
    <>
      <div className="accordion" id={`accordionFlushExample-${parentNumber}`}>
        <div className="accordion-item">
          <h2 className="accordion-header" id={`flush-heading${parentNumber}`}>
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#flush-collapse${parentNumber}`}
              aria-expanded="false"
              aria-controls={`flush-collapse${parentNumber}`}
            >
              Parent Service Check - In #{parentNumber}
            </button>
          </h2>
          <div
            id={`flush-collapse${parentNumber}`}
            className="accordion-collapse collapse"
            aria-labelledby={`flush-heading${parentNumber}`}
            data-bs-parent={`#accordionFlushExample-${parentNumber}`}
          >
            <div className="accordion-body">
              {childs.length === 0 ? (
                <div className="no-data-message">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <span className="emoji" role="img" aria-label="No Data">
                      😕
                    </span>
                    <div>
                      <p>No childs available.</p>
                      <button
                        type="button"
                        className="available_button"
                        data-opportunity-id={parentId}
                        onClick={() => onCreateChilds(parentId)}
                      >
                        Create Childs
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="childs_container">
                    {childs.map((child) => (
                      <div key={child.servicecheckinid}>
                        <p>
                          Service Check - In{" "}
                          <strong>#{child.servicecheckinname}</strong>
                        </p>
                      </div>
                    ))}
                  </div>

                  <button
                    type="button"
                    className="accordion_button"
                    data-opportunity-id={parentId}
                    onClick={() => onCreateOpportunity(parentId)}
                  >
                    Load Parent Childs
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
