import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import "./newAdressCustomer.css";
import requester from "../rentals/requester";
import countries from "../../json/countries.json";
import states from "../../json/states.json";
import LoadingSpinner from "../loadingSpinner/Spinner";

const NewAddressCustomer = (props) => {
  const { customer, customerId } = props;
  const [loading, setLoading] = useState(false);

  let consumer = JSON.parse(localStorage.getItem("consumer"));

  useEffect(() => {
    const country = document.getElementById("country");
    countries.forEach((countryObj) => {
      const option = document.createElement("option");
      option.value = countryObj.id;
      option.innerHTML = countryObj.name;
      country.appendChild(option);

      // TODO: select default country united states
      // if (countryObj.name === "United States") {
      //   country.value = countryObj.id;
      // }
    });

    const state = document.getElementById("state");
    states.forEach((stateObj) => {
      const option = document.createElement("option");
      option.value = stateObj.code;
      option.innerHTML = stateObj.state;
      state.appendChild(option);
    });
  }, []);

  function validateForm(value) {
    let myValue = value.target.value;

    if (value.target.nextElementSibling === null) {
      return;
    }

    if (value.target.nodeName === "SELECT") {
      myValue = value.target.options[value.target.selectedIndex].value;

      if (myValue === "") {
        return (value.target.nextElementSibling.style.display = "block");
      } else {
        value.target.nextElementSibling.style.display = "none";
      }
    }

    if (myValue === "") {
      return (value.target.nextElementSibling.style.display = "block");
    } else {
      value.target.nextElementSibling.style.display = "none";
    }
  }

  const handleClose = () => {
    const country = document.getElementById("country");
    const attention = document.getElementById("attention");
    const phone = document.getElementById("addressPhone");
    const addressOne = document.getElementById("addressOne");
    const addressTwo = document.getElementById("addressTwo");
    const city = document.getElementById("city");
    const state = document.getElementById("state");
    const zip = document.getElementById("zip");
    const labelAddress = document.getElementById("labelAddress");
    const addressTextArea = document.getElementById("addressTextArea");

    country.value = "";
    attention.value = "";
    phone.value = "";
    addressOne.value = "";
    addressTwo.value = "";
    city.value = "";
    state.value = "";
    zip.value = "";
    labelAddress.value = "";
    addressTextArea.value = "";
  };

  const handleSetAddress = () => {
    const country = document.getElementById("country").value;
    const countryName = countries.find((countryObj) => {
      return countryObj.id === country;
    });
    const attention = document.getElementById("attention").value;
    const addressee = document.getElementById("addressee").value;
    const addressOne = document.getElementById("addressOne").value;
    const addressTwo = document.getElementById("addressTwo").value;
    const city = document.getElementById("city").value;
    const state = document.getElementById("state").value;
    const zip = document.getElementById("zip").value;
    const addressTextArea = document.getElementById("addressTextArea");
    if (countryName === undefined) {
      addressTextArea.value = `Address:
      ${attention}
      ${addressee}
      ${addressOne}
      ${addressTwo}
      ${city} ${state} ${zip}
      `;
      return;
    }

    addressTextArea.value = `Address:
    ${attention}
    ${addressee}
    ${addressOne}
    ${addressTwo}
    ${city} ${state} ${zip}
    ${countryName.name}
    `;

    validateForm({ target: document.getElementById("country") });
    validateForm({ target: document.getElementById("state") });
  };

  const handleSaveAddress = () => {
    if (
      validateForm({ target: document.getElementById("country") }) ||
      validateForm({ target: document.getElementById("attention") }) ||
      validateForm({ target: document.getElementById("addressee") }) ||
      validateForm({ target: document.getElementById("addressPhone") }) ||
      validateForm({ target: document.getElementById("addressOne") }) ||
      validateForm({ target: document.getElementById("addressTwo") }) ||
      validateForm({ target: document.getElementById("city") }) ||
      validateForm({ target: document.getElementById("state") }) ||
      validateForm({ target: document.getElementById("zip") })
    )
      return;

    if (document.getElementById("addressPhone").value.length < 8) {
      const Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: "warning",
        html: `<h5>Driver phone number must be 8 digits</h5>`,
      });
      return;
    }

    setLoading(true);

    const country = document.getElementById("country").value;
    const attention = document.getElementById("attention").value;
    const addressee = document.getElementById("addressee").value;
    const phone = document.getElementById("addressPhone").value;
    const addressOne = document.getElementById("addressOne").value;
    const addressTwo = document.getElementById("addressTwo").value;
    const city = document.getElementById("city").value;
    const state = document.getElementById("state").value;
    const zip = document.getElementById("zip").value;
    const labelAddress = document.getElementById("labelAddress").value;
    const addressTextArea = document.getElementById("addressTextArea").value;

    const address = {
      customeraddress: {
        attention: attention,
        customerid: customerId,
        label: labelAddress,
        addressee: addressee,
        address1: addressOne,
        address2: addressTwo,
        city: city,
        state: state,
        country: country,
        zipcode: zip,
        phone: phone,
      },
    };

    requester
      .dynamicRequest(
        `${process.env.REACT_APP_MY_KEY_SERVER}/postNewAddressToCustomer`,
        "POST",
        {
          companyId: consumer.netsuiteInstance,
          endpoint: "postNewAddressUrl",
          customeraddress: {
            attention: attention,
            customerid: customerId,
            label: labelAddress,
            addressee: addressee,
            address1: addressOne,
            address2: addressTwo,
            city: city,
            state: state,
            country: country,
            zipcode: zip,
            phone: phone,
          },
        }
      )
      .then((res) => {
        if (res.status === 400) {
          setLoading(false);
          Swal.fire({
            title: res.message,
            icon: "warning",
            confirmButtonText: "Ok",
            timer: 6000,
          });
          return;
        }
        const objToProps = {
          address,
          addressTextArea,
          res,
        };
        props.onAddAddress(objToProps);

        Swal.fire({
          icon: "success",
          title: "Address added successfully",
          showConfirmButton: true,
          timer: 1500,
        });
        const closebtn = document.getElementById("close-btn-new-customer");
        closebtn.click();
        setLoading(false);
      });
  };

  return (
    <>
      <button
        className="btn btn-outline-secondary"
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal2"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <svg
          width="16"
          height="16"
          className="bi bi-plus-lg"
          viewBox="0 0 16 16"
        >
          <path d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
        </svg>
      </button>

      <div
        className="modal fade"
        id="exampleModal2"
        tabIndex="-1"
        aria-labelledby="exampleModal2Label"
        aria-hidden="true"
      >
        {loading && <LoadingSpinner />}

        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModal2Label">
                New Address
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="close-btn-new-customer"
                onClick={() => handleClose()}
              ></button>
            </div>
            <div className="modal-body grid-container">
              <div className="fields">
                <section className="flex-row">
                  <div className="mb-3">
                    <label className="form-label">Country</label>
                    <select
                      className="form-select"
                      id="country"
                      onChange={() => handleSetAddress()}
                      onKeyUp={(e) => validateForm(e)}
                    >
                      <option></option>
                    </select>
                    <span className="error">Country is a required field</span>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Attention</label>
                    <input
                      type="text"
                      className="form-control"
                      id="attention"
                      onChange={() => handleSetAddress()}
                      onKeyUp={(e) => validateForm(e)}
                    />
                    <span className="error">Attention is a required field</span>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Addressee</label>
                    <input
                      type="text"
                      className="form-control"
                      id="addressee"
                      onChange={() => handleSetAddress()}
                      onKeyUp={(e) => validateForm(e)}
                      value={customer}
                      disabled
                    />
                    <span className="error">Addressee is a required field</span>
                  </div>
                </section>

                <section className="flex-row">
                  <div className="mb-3">
                    <label className="form-label">Phone</label>
                    <input
                      type="text"
                      className="form-control"
                      id="addressPhone"
                      onChange={() => handleSetAddress()}
                      onKeyUp={(e) => validateForm(e)}
                    />
                    <span className="error">Phone is a required field</span>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Address 1</label>
                    <input
                      type="text"
                      className="form-control"
                      id="addressOne"
                      onChange={() => handleSetAddress()}
                      onKeyUp={(e) => validateForm(e)}
                    />
                    <span className="error">Address 1 is a required field</span>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Address 2</label>
                    <input
                      type="text"
                      className="form-control"
                      id="addressTwo"
                      onChange={() => handleSetAddress()}
                      onKeyUp={(e) => validateForm(e)}
                    />
                    <span className="error">Address 2 is a required field</span>
                  </div>
                </section>

                <section className="flex-row">
                  <div className="mb-3">
                    <label className="form-label">City</label>
                    <input
                      type="text"
                      className="form-control"
                      id="city"
                      onChange={() => handleSetAddress()}
                      onKeyUp={(e) => validateForm(e)}
                    />
                    <span className="error">City is a required field</span>
                  </div>

                  <div className="mb-3">
                    <label className="form-label">State</label>
                    <select
                      className="form-select"
                      id="state"
                      onChange={() => handleSetAddress()}
                      onKeyUp={(e) => validateForm(e)}
                    >
                      <option></option>
                      <option value="TX">Texas</option>
                      <option value="AL">Alabama</option>
                    </select>
                    <span className="error">State is a required field</span>
                  </div>

                  <div className="mb-3">
                    <label className="form-label">ZIP</label>
                    <input
                      type="text"
                      className="form-control"
                      id="zip"
                      onChange={() => handleSetAddress()}
                      onKeyUp={(e) => validateForm(e)}
                    />
                    <span className="error">ZIP is a required field</span>
                  </div>
                </section>

                <section>
                  <div className="mb-3">
                    <label className="form-label">Label</label>
                    <input
                      type="text"
                      className="form-control"
                      id="labelAddress"
                      onKeyUp={(e) => validateForm(e)}
                    />
                  </div>
                  <div className="mb-3">
                    <textarea
                      className="form-control textarea"
                      id="addressTextArea"
                      rows="7"
                      disabled
                      onKeyUp={(e) => validateForm(e)}
                      value="Address:"
                    ></textarea>
                  </div>
                </section>
              </div>
            </div>
            <section className="flex-position-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => handleClose()}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => handleSaveAddress()}
              >
                Save
              </button>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewAddressCustomer;
