import React from "react";
import "./spinner.css";

const LoadingSpinner = () => {
  return (
    <div className="background-loader">
      <div className="loader"></div>
    </div>
  );
};

export default LoadingSpinner;
