import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../components/header/header";
import DataTable from "react-data-table-component";
import CustomModal from "./CustomModal";
import ModalNewDelivery from "../../components/modalNewDelivery/NewDelivery";
import NewAddressCustomer from "../../components/modalNewAddress/NewAddressCustomer";
import requester from "../../components/rentals/requester";
import LoadingSpinner from "../../components/loadingSpinner/Spinner";
import SignaturePad from "react-signature-canvas";
import ErrorBoundary from "../../ErrorBoundary";
import Swal from "sweetalert2";
import moment from "moment";
import "./dropoff.css";

const BoundaryComponent = () => {
  const rental = useLocation();
  const {
    customerName,
    customerId,
    rental: rentalName,
    rentalId,
    hardwareForDropOff,
    hardwareForReturn,
    type,
  } = rental.state;

  const hardware = hardwareForDropOff ? hardwareForDropOff : hardwareForReturn;

  let deliveryLinesArray = [];
  let signPad = useRef({});
  let signPad64 = "";

  const [modalShow, setModalShow] = useState(false);
  const [dropOffSelectOptions, setDropOffSelectOptions] = useState([]);
  const [deliveryOptions, setDeliveryChoice] = useState(false);
  const [rentals, setRentals] = useState([]);
  const [saved, setSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [returnLocations, setReturnLocations] = useState([]);
  const [selectedValues, setSelectedValues] = useState({});

  let consumer = JSON.parse(localStorage.getItem("consumer"));

  const columns = [
    {
      name: "Item",
      selector: (row) => row.itemname,
      sortable: true,
    },
    {
      name: "Quantity",
      selector: (row) => row.quantity,
      sortable: true,
    },
    {
      name: "Hardware Record",
      selector: (row) => row.hardwarename,
      sortable: true,
    },
    {
      name: "Serial",
      selector: (row) => row.serial,
      sortable: true,
    },
    {
      name: "Rental Location",
      selector: (row) => (
        <select
          className="renLocationSelect"
          id={`rental${row.id}`}
          disabled={type === "ReturnDelivery" ? true : false}
        >
          <option
            value={
              type === "ReturnDelivery" ? row.location : row.defaultLocation
            }
            key={row.id}
          >
            {type === "ReturnDelivery"
              ? row.locationName
              : row.defaultLocationName}
          </option>
        </select>
      ),
      sortable: true,
    },
    {
      name: "Return Location",
      selector: (row) => {
        return (
          <select
            className="renLocationSelect custom_style_select"
            id={`return${row.id}`}
            value={selectedValues[row.id] || row.defaultLocation || ""}
            onChange={(event) => handleReturnLocationChange(event, row)}
            disabled={type === "Delivery" ? true : false}
          >
            {returnLocations.map((location) => (
              <option value={location.id} key={location.id}>
                {location.name}
              </option>
            ))}
          </select>
        );
      },
      sortable: true,
    },
    {
      name: "Transfer To Bin",
      selector: (row) => row.transfertobin,
      sortable: true,
    },
    {
      name: "Action",
      accessor: "id",
      cell: ({ id }) => (
        <button
          type="button"
          onClick={() => handleDelete(id)}
          className="delete-button"
        >
          Delete
        </button>
      ),
    },
  ];

  useEffect(() => {
    try {
      setLoading(true);

      requester
        .dynamicRequest(
          `${process.env.REACT_APP_MY_KEY_SERVER}/rentalsInfo`,
          "POST",
          {
            companyId: consumer.netsuiteInstance,
            endpoint: "rentalsInfo",
            hardwareArray: hardware,
          }
        )
        .then((res) => {
          const { items, returnLocations } = res;

          if (res.status === 400) {
            setLoading(false);
            Swal.fire({
              title: res.message,
              icon: "warning",
              confirmButtonText: "Ok",
              timer: 4000,
            });
            return;
          }

          let idCounter = 1;
          let mappedItems = items.map((item) => {
            const newItem = {
              id: idCounter,
              itemname: item.itemName,
              itemid: item.itemId,
              quantity: item.quantity,
              hardwarename: item.hardwareName,
              hardwareid: item.hardwareId,
              serial: item.hardwareName,
              rentallocation: "",
              rentallocationid: "",
              location: item.location,
              locationName: item.locationName,
              defaultLocationName: item.defaultLocationName,
              defaultLocation: item.defaultLocation,
              transfertobin: "-",
            };
            idCounter++;
            return newItem;
          });

          setRentals(mappedItems);
          setReturnLocations(returnLocations);
          setLoading(false);
        });

      requester
        .dynamicRequest(
          `${process.env.REACT_APP_MY_KEY_SERVER}/getDropOffSelect`,
          "POST",
          {
            companyId: consumer.netsuiteInstance,
            endpoint: "getDropOffSelectUrl",
            customerid: customerId,
          }
        )
        .then((res) => {
          if (res.status === 400) {
            setLoading(false);
            Swal.fire({
              title: res.message,
              icon: "warning",
              confirmButtonText: "Ok",
              timer: 6000,
            });
            return;
          }
          res.forEach((item) => {
            dropOffSelectOptions.push({
              id: item.id,
              address: item.label,
              full_address: item.full_address,
            });
          });
          setDropOffSelectOptions(dropOffSelectOptions);

          const dropOffList = document.getElementById("dropOffSelect");
          dropOffSelectOptions.forEach((item) => {
            const option = document.createElement("option");
            option.value = item.address || item.full_address;
            option.text = item.address || item.full_address;
            dropOffList.add(option);
          });
        });

      requester
        .dynamicRequest(
          `${process.env.REACT_APP_MY_KEY_SERVER}/searchDeliveryNumber`,
          "POST",
          {
            companyId: consumer.netsuiteInstance,
            endpoint: "getDeliverySearchUrl",
            date: "",
            deliverynumber: "",
          }
        )
        .then((res) => {
          res.forEach((item) => {
            if (!item) return;
            deliveryLinesArray.push({
              deliverynumber: item.deliverynumber,
              deliverid: item.deliverid,
              deliveryday: item.deliveryday,
              drivername: item.drivername,
              driverphone: item.driverphone,
            });
            setDeliveryChoice(deliveryLinesArray);

            const deliveryNumber = document.getElementById("deliveryNumber");
            const option = document.createElement("option");
            option.value = item.deliverid;
            option.text = item.deliverynumber;
            deliveryNumber.add(option);
          });
        });
    } catch (error) {
      alert(error);
    }
  }, []);

  const handleReturnLocationChange = (event, row) => {
    const newSelectedValues = { ...selectedValues };
    newSelectedValues[row.id] = event.target.value;
    setSelectedValues(newSelectedValues);
  };

  function validateForm(value) {
    let myValue = value.target.value;

    if (value.target.nextElementSibling === null) {
      return;
    }

    if (value.target.nodeName === "SELECT") {
      myValue = value.target.options[value.target.selectedIndex].value;

      if (myValue === "") {
        return (value.target.nextElementSibling.style.display = "block");
      } else {
        value.target.nextElementSibling.style.display = "none";
      }
    }

    if (myValue === "") {
      return (value.target.nextElementSibling.style.display = "block");
    } else {
      value.target.nextElementSibling.style.display = "none";
    }
  }

  const createDropOff = () => {
    if (
      validateForm({ target: document.getElementById("dropofflist") }) ||
      validateForm({ target: document.getElementById("dateDropOff") }) ||
      validateForm({ target: document.getElementById("driverName") }) ||
      validateForm({ target: document.getElementById("driverNumber") }) ||
      validateForm({ target: document.getElementById("deliveryNumber") }) ||
      validateForm({ target: document.getElementById("dropOffSelect") }) ||
      validateForm({ target: document.getElementById("address") })
    )
      return;

    if (document.getElementById("driverNumber").value.length < 8) {
      const Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: "warning",
        html: `<h5>Driver phone number must be 8 digits</h5>`,
      });
      return;
    }

    const dropOffLinesMapped = rentals.map((item) => {
      //get the locations ids from the selects on the table
      let rentalSelect = document.getElementById(`rental${item.id}`);
      let returnSelect = document.getElementById(`return${item.id}`);
      rentalSelect = rentalSelect.options[rentalSelect.selectedIndex].value;
      returnSelect = returnSelect.options[returnSelect.selectedIndex].value;

      return {
        hardwarerecord: item.hardwareid,
        itemid: item.itemid,
        quantity: item.quantity,
        serial: item.serial,
        rentallocation: item.rentallocation
          ? item.rentallocation
          : rentalSelect,
        returnLocation: type === "ReturnDelivery" ? returnSelect : "",
        rentalid: rental.state.rentalId,
        itemname: item.itemname,
      };
    });

    const dropOffStatus = document.getElementById("dropofflist").value;
    const driverName = document.getElementById("driverName").value;
    const driverNumber = document.getElementById("driverNumber").value;
    const deliveryNumber = document.getElementById("deliveryNumber").value;
    const dropOffSelect = document.getElementById("dropOffSelect").value;
    const address = document.getElementById("address").value;
    let dateDropOff = document.getElementById("dateDropOff").value;
    let dateDropOffArray = dateDropOff.split("-");
    let dateDropOffFormatted = `${dateDropOffArray[1]}/${dateDropOffArray[2]}/${dateDropOffArray[0]}`;

    let dateAndTimeConcat = dateDropOffFormatted;
    const addressSelected = dropOffSelectOptions.find((item) => {
      return item.address || item.full_address === dropOffSelect;
    });
    if (addressSelected === undefined) return;

    let signatureBase64 = document.getElementById("base64_signature").value;
    if (!signatureBase64) {
      Swal.fire({
        icon: "warning",
        title: "Please add a signature!",
        timer: 2000,
      });
      return;
    }

    setLoading(true);
    requester
      .dynamicRequest(
        `${process.env.REACT_APP_MY_KEY_SERVER}/createDropOff`,
        "POST",
        {
          companyId: consumer.netsuiteInstance,
          endpoint: "createDropOffUrl",
          dropOff: {
            status: dropOffStatus,
            drivername: driverName,
            drivernumber: driverNumber,
            rentalreservation: rentalId,
            deliverynumber: deliveryNumber,
            customer: parseInt(customerId),
            dropoffdate: dateAndTimeConcat,
            address: addressSelected.id,
            addressfull: address,
            isdeliveryfromcustomer: type === "ReturnDelivery" ? true : false,
          },
          signature: signatureBase64,
          dropOffLines: dropOffLinesMapped,
        }
      )
      .then((res) => {
        if (res.status === 400) {
          setLoading(false);
          Swal.fire({
            title: res.message,
            icon: "warning",
            confirmButtonText: "Ok",
            timer: 6000,
          });
          return;
        }
        const { recordid, recordurl } = res;
        setLoading(false);

        let timerInterval;
        Swal.fire({
          icon: "success",
          title: "Drop off created" + ", ID: " + recordid,
          html: `A new tab will open with the rental in netsuite as soon as the alert is closed or press the following link to go now: 
              <a href="${recordurl}" target="_blank">click here</a>`,
          timer: 5000,
          timerProgressBar: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          willClose: () => {
            clearInterval(timerInterval);
          },
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            window.open(recordurl, "_blank").focus();
            Swal.close();
            window.location.href = "/home/Rentals";
            return;
          }
          window.open(recordurl, "_blank").focus();
          Swal.close();
          window.location.href = "/home/Rentals";
        });
      });
  };

  const receiveDate = (deliveryArray) => {
    const { driverName, driverPhoneNumber, deliverid } = deliveryArray[0];
    const deliverySelected = deliveryOptions.find(
      (item) => item.deliverid === deliverid
    );
    if (!deliverySelected) return;

    const MomentDate = moment(new Date(deliverySelected.deliveryday)).format(
      "YYYY-MM-DD"
    );

    document.getElementById("dateDropOff").value = MomentDate;
    document.getElementById("deliveryNumber").value = deliverid;
    document.getElementById("driverName").value = driverName;
    document.getElementById("driverNumber").value = driverPhoneNumber;

    validateForm({ target: document.getElementById("driverName") });
    validateForm({ target: document.getElementById("driverNumber") });
    validateForm({ target: document.getElementById("dateDropOff") });
    validateForm({ target: document.getElementById("deliveryNumber") });
  };

  const receiveDataModalNewDelivery = (data) => {
    const { drivername, drivernumber, recordid, recordname } = data.res;

    const MomentDate = moment(new Date(data.deliveryDayFormatted)).format(
      "YYYY-MM-DD"
    );

    deliveryOptions.push({
      deliverid: recordid.toString(),
      deliveryday: data.deliveryDayFormatted,
      deliverynumber: recordname,
      drivername: drivername,
      driverphone: drivernumber,
    });

    document.getElementById("dateDropOff").value = MomentDate;
    const newOption = document.createElement("option");
    newOption.value = recordid;
    newOption.text = recordname;
    document.getElementById("deliveryNumber").add(newOption);
    const deliveryNumber = document.getElementById("deliveryNumber");
    deliveryNumber.value = recordid;

    document.getElementById("driverName").value = drivername;
    document.getElementById("driverNumber").value = drivernumber;
  };

  const handleSelectDelivery = (e) => {
    if (validateForm({ target: document.getElementById("deliveryNumber") }))
      return;

    const deliveryNumber = e.target.value;
    const deliverySelected = deliveryOptions.find(
      (item) => item.deliverid === deliveryNumber
    );
    if (!deliverySelected) return;

    const MomentDate = moment(new Date(deliverySelected.deliveryday)).format(
      "YYYY-MM-DD"
    );

    document.getElementById("dateDropOff").value = MomentDate;
    document.getElementById("driverName").value = deliverySelected.drivername;
    document.getElementById("driverNumber").value =
      deliverySelected.driverphone;

    validateForm({ target: document.getElementById("driverName") });
    validateForm({ target: document.getElementById("driverNumber") });
    validateForm({ target: document.getElementById("dateDropOff") });
  };

  const receiveDataModalNewAddress = (data) => {
    const { label } = data.address.customeraddress;
    const full_address = data.addressTextArea;

    dropOffSelectOptions.push({
      id: parseInt(data.res.addressid),
      address: label,
      full_address: full_address,
    });

    const newOption = document.createElement("option");
    newOption.value = label;
    newOption.text = label;

    document.getElementById("dropOffSelect").add(newOption);
    document.getElementById("dropOffSelect").value = label;
    document.getElementById("address").value = full_address;
  };

  const handleSelectAddress = (e) => {
    if (validateForm({ target: document.getElementById("dropOffSelect") }))
      return;
    const address = e.target.value;

    const addressSelected = dropOffSelectOptions.find(
      (item) => item.address === address
    );

    if (!addressSelected) return;
    document.getElementById("address").value = addressSelected.full_address;

    validateForm({ target: document.getElementById("address") });
  };

  const handleChangeDate = () => {
    if (validateForm({ target: document.getElementById("dateDropOff") }))
      return;
  };

  const clear = () => {
    signPad.current.clear();
    var base64 = document.getElementById("base64_signature");
    base64.value = "";
    setSaved(false);
  };

  const save = () => {
    if (!signPad.current.isEmpty()) {
      setSaved(true);
      signPad64 = signPad.current.toDataURL();
      var base64 = document.getElementById("base64_signature");
      base64.value = signPad64;
    }
  };

  const handleDelete = (id) => {
    setRentals((prevData) => prevData.filter((d) => d.id !== id));
  };

  return (
    <>
      {loading && <LoadingSpinner />}

      <Header />

      <section className="rental-header base-margin">
        <div>
          <h4 className="title">Rental Delivery</h4>

          <CustomModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            title={"Delivery Search"}
            onSearch={receiveDate}
          />
        </div>

        <div>
          <button type="button" className="secondary-button">
            <a href="/home/Rentals">
              <svg
                width="24"
                height="24"
                className="bi bi-arrow-left"
                viewBox="0 0 16 16"
              >
                <path d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
              </svg>
            </a>
          </button>
        </div>
      </section>

      <div className="rental-container base-margin">
        <section className="info1">
          <div className="mb-3">
            <label className="form-label">Delivery Status</label>
            <select
              className="form-select"
              id="dropofflist"
              onKeyUp={(e) => validateForm(e)}
            >
              <option value="1">Scheduled</option>
              <option value="2">In Progress</option>
              <option value="3">Completed</option>
            </select>
            <span className="error">Delivery status is a required field</span>
          </div>

          <label className="form-label">Delivery Time</label>
          <div className="mb-3">
            <input
              type="date"
              className="form-control"
              id="dateDropOff"
              onChange={() => handleChangeDate()}
              onKeyUp={(e) => validateForm(e)}
            />
            <span className="error">Date is a required field</span>
          </div>

          <div className="mb-3">
            <label className="form-label">Driver Name</label>
            <input
              type="text"
              className="form-control"
              id="driverName"
              onKeyUp={(e) => validateForm(e)}
            />
            <span className="error">Driver name is a required field</span>
          </div>
        </section>

        <section className="info2">
          <div className="mb-3">
            <label className="form-label">Driver Phone Number</label>
            <input
              type="text"
              className="form-control"
              id="driverNumber"
              onKeyUp={(e) => validateForm(e)}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
              }}
            />
            <span className="error">
              Driver phone number is a required field
            </span>
          </div>

          <div className="mb-3">
            <label className="form-label">Rental Reservation</label>
            <select className="form-select" id="rentalReservation" disabled>
              <option value={rentalId}>{rentalName}</option>
            </select>
          </div>

          <label className="form-label">Delivery Number</label>
          <div className="mb-3 flex-delivery-create">
            <div className="section-select-1">
              <select
                className="form-select"
                id="deliveryNumber"
                onChange={(e) => handleSelectDelivery(e)}
                onKeyUp={(e) => validateForm(e)}
              >
                <option></option>
              </select>
              <span className="error">Delivery Number is a required field</span>
            </div>

            <div className="section-select-2">
              <ModalNewDelivery
                show={modalShow}
                onHide={() => setModalShow(false)}
                title={"Create Delivery Number"}
                onAddDelivery={receiveDataModalNewDelivery}
              />
            </div>
          </div>

          <div className="mb-3">
            <label className="form-label">Rental Customer</label>
            <select className="form-select" id="customer" disabled>
              <option value={customerId}>{customerName}</option>
            </select>
          </div>
        </section>

        <section className="signature-container">
          <h5>Signature</h5>
          <div className="d-flex flex-column justify-content-center mx-auto mt-3">
            <div className="p-2 signature img-fluid img-thumbnail custom-size">
              <SignaturePad
                canvasProps={{
                  width: 500,
                  height: 200,
                }}
                ref={signPad}
              />
            </div>
            <p></p>
            <div className="d-flex justify-content-between">
              <div className="container">
                <button
                  type="button"
                  className="delete-button custom-size"
                  data-action="clear"
                  onClick={clear}
                >
                  Clear
                </button>
              </div>
              <div className="container">
                <button
                  type="button"
                  className="available_button custom-size custom-loader"
                  data-action="clear"
                  onClick={save}
                >
                  Save &nbsp;
                  {saved && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-check2 white font-weight-bold"
                    >
                      <path
                        fillRule="evenodd"
                        d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L6.5 10.793l6.646-6.647a.5.5 0 0 1 .708 0z"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>

          <div className="container">
            <input type="text" id="base64_signature" hidden></input>
          </div>
        </section>

        <section className="address">
          <label className="form-label">Delivery Select</label>
          <div className="mb-3 flex-delivery-create">
            <div className="section-select-1">
              <select
                className="form-select"
                id="dropOffSelect"
                onChange={(e) => handleSelectAddress(e)}
                onKeyUp={(e) => validateForm(e)}
              >
                <option></option>
              </select>
              <span className="error">Delivery select is a required field</span>
            </div>

            <div className="section-select-2">
              <NewAddressCustomer
                show={modalShow}
                onHide={() => setModalShow(false)}
                onAddAddress={receiveDataModalNewAddress}
                customer={customerName}
                customerId={customerId}
              />
            </div>
          </div>

          <div className="mb-3">
            <label className="form-label">Delivery Address Full</label>
            <textarea
              className="form-control"
              rows="3"
              id="address"
              onKeyUp={(e) => validateForm(e)}
            ></textarea>
            <span className="error">
              Delivery Address Full is a required field
            </span>
          </div>

          <div className="save-button-section">
            <button
              type="button"
              className="available_button"
              onKeyUp={(e) => validateForm(e)}
              onClick={() => createDropOff()}
            >
              &nbsp; Create Delivery &nbsp;
            </button>
          </div>
        </section>
      </div>

      <section className="table-container base-margin">
        <div className="banner">Rental Pickup/Return/Delivery Lines</div>
        <DataTable
          className="dataTable"
          pagination
          columns={columns}
          theme="solarized"
          highlightOnHover
          selectedRows
          responsive
          data={rentals}
          fixedHeader
          fixedHeaderScrollHeight="500px"
        />
      </section>
    </>
  );
};

const DropOff = () => {
  return (
    <ErrorBoundary>
      <BoundaryComponent />
    </ErrorBoundary>
  );
};

export default DropOff;
