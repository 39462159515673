import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { AiOutlineReload } from "react-icons/ai";
import LoadingSpinner from "../loadingSpinner/Spinner";
import Scanner from "../barcodeScanner/Scanner";
import Header from "../header/header";
import "./RentalItems.css";
import { Link } from "react-router-dom";
import FloatingButton from "../floatingButton/FloatingButton";
import requester from "../rentals/requester";

const RentalItems = () => {
  const [search, setSearch] = useState("");
  const [rentalItems, setRentalItems] = useState([]);
  const [filteredRentals, setFilteredRentals] = useState([]);
  const [loading, setLoading] = useState(true);
  const envUrl = process.env.REACT_APP_MY_KEY_SERVER; //REACT_APP_MY_KEY_LOCAL - REACT_APP_MY_KEY_SERVER;

  const columns = [
    {
      name: "Reservation",
      selector: (row) => row.Rental,
      sortable: true,
    },
    {
      name: "Customer",
      selector: (row) => row.Customer,
      sortable: true,
    },
    {
      name: "Start Date",
      selector: (row) => row.StartDate,
      sortable: true,
    },
    {
      name: "End Date",
      selector: (row) => row.EndDate,
      sortable: true,
    },
    {
      button: true,
      name: "Customer Pickup",
      width: "150px",
      selector: (row) => row.action,
      cell: ({ hardwareForPickup, reservation }) =>
        hardwareForPickup.length > 0 ? (
          <Link
            to={"Record"}
            state={{
              hardwareForPickup,
              type: "Pickup",
              reservation,
            }}
          >
            <button type="button" className="available_button">
              Pickup
            </button>
          </Link>
        ) : (
          <button type="button" className="not_available_button">
            Pickup
          </button>
        ),
    },
    {
      button: true,
      name: "Customer Return",
      width: "150px",
      selector: (row) => row.dropOff,
      cell: ({ hardwareForReturn, reservation, locationName, location }) =>
        hardwareForReturn.length > 0 ? (
          <Link
            to={"Record"}
            state={{
              hardwareForReturn,
              type: "Return",
              reservation,
              locationName,
              location,
            }}
          >
            <button type="button" className="available_button">
              Return
            </button>
          </Link>
        ) : (
          <button type="button" className="not_available_button">
            Return
          </button>
        ),
    },
  ];
  let consumer = JSON.parse(localStorage.getItem("consumer"));

  //useEffect to get all rental items
  useEffect(() => {
    loadData();
  }, []);

  //useEffect to search rental items on the table
  useEffect(() => {
    const result = rentalItems.filter((rental) => {
      return (
        rental.Rental.toLowerCase().match(search.toLowerCase()) ||
        rental.Customer.toLowerCase().match(search.toLowerCase()) ||
        rental.StartDate.toLowerCase().match(search.toLowerCase()) ||
        rental.EndDate.toLowerCase().match(search.toLowerCase())
      );
    });

    setFilteredRentals(result);
  }, [search]);

  //functions
  const getBarcodeNumber = (barcodeNumber) => {
    setSearch(barcodeNumber);
  };

  const loadData = async () => {
    setLoading(true);

    try {
      requester
        .dynamicRequest(`${envUrl}/rentals`, "POST", {
          companyId: consumer.netsuiteInstance,
          endpoint: "rentals",
        })
        .then((data) => {
          const rentalArray = Object.entries(data).map(([key, value]) => ({
            Rental: key,
            reservation: value.reservation,
            Customer: value.customer,
            customerId: value.customerId,
            StartDate: value.startDate,
            EndDate: value.endDate,
            hardwareForReturn: value.hardwareForReturn,
            hardwareForPickup: value.hardwareForPickup,
            hardwareForDropOff: value.hardwareForDropOff,
            locationName: value.locationName,
            location: value.location,
          }));

          setRentalItems(rentalArray);
          setFilteredRentals(rentalArray);
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      alert(error);
    }
  };

  return (
    <>
      <Header />

      <div className="table_container">
        <DataTable
          className="dataTable"
          pagination
          columns={columns}
          theme="solarized"
          highlightOnHover
          selectedRows
          responsive
          data={filteredRentals}
          fixedHeader
          fixedHeaderScrollHeight="500px"
          subHeader
          subHeaderComponent={
            <div className="flex_row">
              <input
                type="text"
                placeholder="Search Rental"
                className="form-control"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />

              <Scanner onScan={getBarcodeNumber} />

              <button
                type="button"
                className="btn_scanner hov_only_color"
                onClick={() => loadData()}
              >
                <AiOutlineReload fontSize={25} />
              </button>
            </div>
          }
          progressPending={loading}
          progressComponent={<LoadingSpinner />}
          subHeaderAlign="left"
        />

        <FloatingButton url={"/home"} logout={false} />
      </div>
    </>
  );
};

export default RentalItems;
