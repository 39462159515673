import React, { useEffect, useRef, useState } from "react";
import { BiBarcodeReader } from "react-icons/bi";
import Quagga from "quagga";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { IoCloseSharp } from "react-icons/io5";
import LoadingSpinner from "../loadingSpinner/Spinner";
import "./Scanner.css";

export default function Scanner({ onScan }) {
  const firstUpdate = useRef(true);
  const [isStart, setIsStart] = useState(false);
  const [open, setOpen] = useState(false);
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    return () => {
      if (isStart) stopScanner();
    };
  }, []);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (isStart) startScanner();
    else stopScanner();
  }, [isStart]);

  const onOpenModal = () => {
    setOpen(true);
    setSpinner(true);
    setTimeout(() => {
      setIsStart(true);
    }, 500);
  };

  const onCloseModal = () => {
    setOpen(false);
    setIsStart(false);
  };

  const _onDetected = (res) => {
    stopScanner();
    onScan(res.codeResult.code);

    setTimeout(() => {
      onCloseModal();
    }, 200);
  };

  const startScanner = () => {
    Quagga.init(
      {
        inputStream: {
          type: "LiveStream",
          target: document.querySelector("#scanner-container"),
          constraints: {
            facingMode: "environment",
          },
        },
        numOfWorkers: navigator.hardwareConcurrency,
        locate: true,
        frequency: 1,
        debug: {
          drawBoundingBox: true,
          showFrequency: true,
          drawScanline: true,
          showPattern: true,
        },
        multiple: false,
        locator: {
          halfSample: false,
          patchSize: "large",
          debug: {
            showCanvas: false,
            showPatches: false,
            showFoundPatches: false,
            showSkeleton: false,
            showLabels: false,
            showPatchLabels: false,
            showRemainingPatchLabels: false,
            boxFromPatches: {
              showTransformed: false,
              showTransformedBox: false,
              showBB: false,
            },
          },
        },
        decoder: {
          readers: [
            "code_128_reader",
            "ean_reader",
            "ean_8_reader",
            "code_39_reader",
            "code_39_vin_reader",
            "codabar_reader",
            "upc_reader",
            "upc_e_reader",
            "i2of5_reader",
            "i2of5_reader",
            "2of5_reader",
            "code_93_reader",
          ],
        },
      },
      (err) => {
        if (err) {
          return console.log(err);
        }
        Quagga.start();
        setSpinner(false);
      }
    );
    Quagga.onDetected(_onDetected);
    Quagga.onProcessed((result) => {
      let drawingCtx = Quagga.canvas.ctx.overlay,
        drawingCanvas = Quagga.canvas.dom.overlay;

      if (result) {
        if (result.boxes) {
          drawingCtx.clearRect(
            0,
            0,
            parseInt(drawingCanvas.getAttribute("width")),
            parseInt(drawingCanvas.getAttribute("height"))
          );
          result.boxes
            .filter((box) => box !== result.box)
            .forEach((box) => {
              Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
                color: "green",
                lineWidth: 2,
              });
            });
        }

        if (result.box) {
          Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
            color: "#00F",
            lineWidth: 2,
          });
        }

        if (result.codeResult && result.codeResult.code) {
          Quagga.ImageDebug.drawPath(
            result.line,
            { x: "x", y: "y" },
            drawingCtx,
            { color: "red", lineWidth: 3 }
          );
        }
      }
    });
  };

  const stopScanner = () => {
    Quagga.offProcessed();
    Quagga.offDetected();
    Quagga.stop();
  };

  return (
    <>
      <button className="btn_scanner hov_only_color" onClick={onOpenModal}>
        <BiBarcodeReader fontSize={25} />
      </button>

      <Modal open={open} center closeOnOverlayClick={false}>
        {spinner && <LoadingSpinner />}
        <div className="header_modal">
          <h2>Scan Barcode</h2>
          <button
            onClick={onCloseModal}
            style={{
              cursor: "pointer",
            }}
          >
            <IoCloseSharp fontSize={25} />
          </button>
        </div>

        <div id="scanner-container" />
      </Modal>
    </>
  );
}
