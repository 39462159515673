import { useState } from "react";
import Header from "../../components/header/header";
import { useLocation } from "react-router-dom";
import DriversTable from "../../components/driversTable/DriversTable";
import FloatingButton from "../../components/floatingButton/FloatingButton";
import ModalTimeTracking from "../../components/modalTimeTracking/ModalTimeTracking";
import requester from "../../components/rentals/requester";
import Swal from "sweetalert2";
import LoadingSpinner from "../../components/loadingSpinner/Spinner";

export default function TaskDetail() {
  const [isLoading, setIsLoading] = useState(false);
  const taskDetailsState = useLocation().state;
  const task = taskDetailsState.task;

  const {
    stockItem,
    customerSerial,
    makemodel,
    description,
    servicerequest,
    childOpportunityId,
  } = task.unitDetailsObj;

  const [taskDetails, _setTaskDetails] = useState([
    {
      id: 1,
      label: "Customer",
      value: task.customerName,
    },
    {
      id: 2,
      label: "Service Location",
      value: task.address,
    },
    {
      id: 3,
      label: "Scheduled Date",
      value: task.startdate,
    },
    {
      id: 4,
      label: "Service Type",
      value: task.serviceType,
    },
    {
      id: 5,
      label: "Status",
      value: task.status,
    },
  ]);
  const [unitDetails, _setUnitDetails] = useState([
    {
      id: 1,
      label: "Stock Item",
      value: stockItem,
    },
    {
      id: 2,
      label: "Customer Serial #",
      value: customerSerial,
    },
    {
      id: 3,
      label: "Make / Model",
      value: makemodel,
    },
    {
      id: 4,
      label: "Description",
      value: description,
    },
    {
      id: 5,
      label: "Service Requested",
      value: servicerequest,
    },
  ]);
  const [serviceItems, _setServiceItems] = useState(task.lineItems);
  const [myNotes, setMyNotes] = useState(task.servicenotes);
  const [timeTrackings, setTimeTrackings] = useState(task.lineTimeTrackings);

  const columns = [
    {
      name: "Item",
      selector: (row) => row.itemname,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Quantity",
      selector: (row) => row.quantity,
      sortable: true,
    },
  ];

  const columnsTimeTracking = [
    {
      name: "Internal ID",
      selector: (row) => row.internalid,
      sortable: true,
    },
    {
      name: "Duration",
      selector: (row) => row.duration,
      sortable: true,
    },
    {
      name: "Date Created",
      selector: (row) => row.datecreated,
      sortable: true,
    },
    {
      name: "Case/Task/Event",
      selector: (row) => row.relatedrecord,
      sortable: true,
    },
  ];

  let consumer = JSON.parse(localStorage.getItem("consumer"));
  const envUrl = process.env.REACT_APP_MY_KEY_SERVER; //REACT_APP_MY_KEY_LOCAL - REACT_APP_MY_KEY_SERVER;
  const companyId = consumer.netsuiteInstance; //consumer.netsuiteInstance; - "TSTDRV2359357";

  const updateNotes = async (childOpportunityId) => {
    if (!myNotes ?? "" === "") {
      Swal.fire({
        icon: "warning",
        title: "You need to enter a note to update it.",
        timer: 2000,
      });
      return;
    }

    setIsLoading(true);
    const notes = await postOrGetData("postModuleTask", {
      companyId: companyId,
      endpoint: "postModuleTaskURL",
      type: "updateNotes",
      childOpportunityId: childOpportunityId,
      notes: myNotes,
    });

    if (notes.message === "SUCCESS") {
      setIsLoading(false);
      Swal.fire({
        icon: "success",
        title: "Notes updated successfully",
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      setIsLoading(false);
      Swal.fire({
        icon: "error",
        title: "An error occurred while updating the notes",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const markAsComplete = async () => {
    setIsLoading(true);
    const markAsComplete = await postOrGetData("postModuleTask", {
      companyId: companyId,
      endpoint: "postModuleTaskURL",
      type: "markAsCompleted",
      taskId: task.internalid,
    });

    if (markAsComplete.message === "SUCCESS") {
      setIsLoading(false);
      Swal.fire({
        icon: "success",
        title: "Task marked as completed successfully",
        showConfirmButton: false,
        timer: 2000,
      });

      window.location.href = "/home/Service";
    } else {
      setIsLoading(false);
      Swal.fire({
        icon: "error",
        title: "An error occurred marking the task as completed",
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };

  const onSuccessTimeTracking = async (success) => {
    if (success) {
      const tableTimeTrackingData = await postOrGetData(
        "postObtainNetSuiteData",
        {
          companyId: companyId,
          endPoint: "obtainNetSuiteDataURL",
          typeOfConsult: 13,
          taskId: task.internalid,
        }
      );

      setTimeTrackings(tableTimeTrackingData[0].lineTimeTrackings);
    }
  };

  const postOrGetData = async (endpoint, body) => {
    try {
      const res = await requester.dynamicRequest(
        `${envUrl}/${endpoint}`,
        "POST",
        body
      );
      return res.responseService ? res.responseService : res;
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      <Header />

      {isLoading && <LoadingSpinner />}

      <h3
        style={{
          margin: "1.5rem",
        }}
      >
        {task.title}
      </h3>
      <div
        style={{
          margin: "1.5rem",
        }}
      >
        <div
          className="responsive_grid_container_200_phone"
          style={{
            margin: "1rem",
          }}
        >
          <section className="box_shadow">
            <h4
              className="title_banner"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              Task Details
            </h4>
            <div className="container_grid_info_opp">
              {taskDetails.map((label) => (
                <div className="flex_info_opp" key={label.id}>
                  <label>{label.label}</label>
                  <strong
                    style={{
                      wordWrap: "break-word",
                    }}
                  >
                    {label.value}
                  </strong>
                </div>
              ))}
            </div>
          </section>

          <section className="box_shadow">
            <h4
              className="title_banner"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              Unit Details
            </h4>
            <div className="container_grid_info_opp">
              {unitDetails.map((label) => (
                <div className="flex_info_opp" key={label.id}>
                  <label>{label.label}</label>
                  <strong
                    style={{
                      wordWrap: "break-word",
                    }}
                  >
                    {label.value}
                  </strong>
                </div>
              ))}
            </div>
          </section>
        </div>

        <DriversTable
          columns={columns}
          data={serviceItems}
          needBanner={true}
          bannerText={"Service Item Details"}
        />

        <DriversTable
          columns={columnsTimeTracking}
          data={timeTrackings}
          needBanner={true}
          bannerText={"Time Tracking"}
        />

        <h4 className="title_banner">Technician Service Notes</h4>
        <div
          style={{
            margin: "1rem 0.6rem",
          }}
        >
          <textarea
            placeholder="Write your notes..."
            className="form-control"
            defaultValue={myNotes}
            onChange={(e) => setMyNotes(e.target.value)}
          ></textarea>
        </div>

        <section className="buttons_end">
          <button
            className="available_button"
            disabled={task.status === "COMPLETE"}
            onClick={() => markAsComplete()}
          >
            Mark As Complete
          </button>
          <button
            className="available_button"
            onClick={() => updateNotes(childOpportunityId)}
          >
            Update Notes
          </button>
          <ModalTimeTracking
            title={task.title}
            task={task}
            onSuccess={onSuccessTimeTracking}
          />
        </section>
      </div>

      <FloatingButton url="/home/Service" />
    </>
  );
}
