import React, { useState } from "react";
import requester from "../rentals/requester";
import Swal from "sweetalert2";
import "./newDelivery.css";
import LoadingSpinner from "../loadingSpinner/Spinner";

const ModalNewDelivery = (props) => {
  const { title } = props;
  const [loading, setLoading] = useState(false);

  let consumer = JSON.parse(localStorage.getItem("consumer"));

  const handleCloseModal = () => {
    document.getElementsByClassName("driverName")[0].value = "";
    document.getElementById("phone").value = "";
    document.getElementById("deliveryDate").value = "";
  };

  const handleAddDelivery = () => {
    let name = document.getElementsByClassName("driverName")[0].value;
    let phone = document.getElementById("phone").value;
    let dateInput = document.getElementById("deliveryDate").value;

    if (name === "" || phone === "" || dateInput === "") {
      const Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: "warning",
        html: `<h5>Please fill driver name / delivery day / driver phone number</h5>`,
      });
      return;
    }

    if (document.getElementById("phone").value.length < 8) {
      const Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: "warning",
        html: `<h5>Driver phone number must be 8 digits</h5>`,
      });
      return;
    }

    setLoading(true);

    const date = new Date(dateInput);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const hours12 = hours > 12 ? hours - 12 : hours;
    const minutes12 = minutes < 10 ? "0" + minutes : minutes;
    const seconds12 = seconds < 10 ? "0" + seconds : seconds;
    const amPm = hours >= 12 ? "pm" : "am";

    const dropOffTimeFormattedWithAmPm = `${month}/${day}/${year} ${hours12}:${minutes12}:${seconds12} ${amPm}`;

    requester
      .dynamicRequest(
        `${process.env.REACT_APP_MY_KEY_SERVER}/postNewDelivery`,
        "POST",
        {
          companyId: consumer.netsuiteInstance,
          endpoint: "postNewDeliveryUrl",
          name: name,
          phone: phone,
          date: dropOffTimeFormattedWithAmPm,
        }
      )
      .then((res) => {
        if (res.status === 400) {
          setLoading(false);
          Swal.fire({
            title: res.message,
            icon: "warning",
            confirmButtonText: "Ok",
            timer: 4000,
          });
          return;
        }
        const closebtn = document.getElementById("close-btn-new-delivery");
        Swal.fire({
          icon: "success",
          title: "Delivery number added successfully",
          showConfirmButton: true,
          timer: 1500,
        });

        closebtn.click();
        const objToSend = {
          res: res,
          deliveryDayFormatted: dropOffTimeFormattedWithAmPm,
        };
        props.onAddDelivery(objToSend);
        setLoading(false);
      });
  };

  return (
    <>
      <button
        className="btn btn-outline-secondary"
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal1"
      >
        <svg
          width="16"
          height="16"
          className="bi bi-plus-lg"
          viewBox="0 0 16 16"
        >
          <path d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
        </svg>
      </button>

      <div
        className="modal fade"
        id="exampleModal1"
        tabIndex="-1"
        aria-labelledby="exampleModal1Label"
        aria-hidden="true"
      >
        {loading && <LoadingSpinner />}
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModal1Label">
                {title}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="close-btn-new-delivery"
                onClick={() => handleCloseModal()}
              ></button>
            </div>
            <div className="modal-body grid-container">
              <div className="grid-item">
                <div className="mb-3">
                  <label className="form-label">Driver Name</label>
                  <input type="text" className="form-control driverName" />
                </div>
              </div>
              <div className="grid-item">
                <div className="mb-3">
                  <label className="form-label">Delivery Day</label>
                  <input
                    type="datetime-local"
                    className="form-control"
                    id="deliveryDate"
                  />
                </div>
              </div>
              <div className="grid-item">
                <div className="mb-3">
                  <label className="form-label">Driver Phone Number</label>
                  <input
                    type="number"
                    className="form-control"
                    id="phone"
                    onChange={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, "");
                    }}
                  />
                </div>
              </div>
            </div>
            <section className="flex-position-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => handleAddDelivery()}
              >
                Save
              </button>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalNewDelivery;
