import { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import MosaicOption from "./components/mosaicsOptions/MosaicOption";
import Login from "./pages/login/Login";
import DropOff from "./pages/rentaldropoff/DropOff";
import Header from "./components/header/header";
import WarehouseItems from "./components/items/RentalItems";
import NotFound from "./pages/notfound/NotFound";
import PickupOrReturn from "./components/rentals/PickupOrReturn";
import DriverPageDelivery from "./pages/driver/DriverPageDelivery";
import DriverPagePickRet from "./pages/driver/DriverPagePickRet";
import DriverMarkComp from "./pages/driver/DriverPageMarkComp";
import FieldService from "./pages/FieldService/FieldService";
import TaskDetail from "./pages/FieldService/TaskDetail";
import FloatingNotification from "./components/floatingNotification/FloatingNotification";

function App() {
  // const location = useLocation(); // Get the current location
  // const [showNotification, setShowNotification] = useState(true);

  // useEffect(() => {
  //   setShowNotification(
  //     !(location.pathname === "/" || location.pathname === "/home")
  //   );
  // }, [location.pathname]);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="home/rentals" element={<WarehouseItems />} />
        <Route path="home/Rentals/Record" element={<PickupOrReturn />} />
        <Route path="home/Rentals/dropoff" element={<DropOff />} />
        <Route path="home/driverDelivery" element={<DriverPageDelivery />} />
        <Route
          path="/driverDelivery/PickUpReturn/:id"
          element={<DriverPagePickRet />}
        />
        <Route
          path="/driverDelivery/PickUpReturn/driverMarkComp/:id"
          element={<DriverMarkComp />}
        />
        <Route path="/home/Service/task/:id" element={<TaskDetail />} />
        <Route path="/home/Service" element={<FieldService />} />
        <Route path="*" element={<NotFound />} />
      </Routes>

      {/* {showNotification && <FloatingNotification />} */}
    </div>
  );
}
function Home() {
  let consumer = JSON.parse(localStorage.getItem("consumer"));
  const myRole = consumer?.role;

  return (
    <>
      <Header />

      <div className="responsive_grid_container">
        {myRole === "1" && (
          <>
            <MosaicOption
              url={"Rentals"}
              title={"Rentals"}
              imageUrl="./rentalImage.svg"
            />

            <MosaicOption
              url={"driverDelivery"}
              title={"Delivery"}
              imageUrl="./delivery.png"
            />
          </>
        )}

        {myRole === "2" && (
          <>
            <MosaicOption
              url={"driverDelivery"}
              title={"Delivery"}
              imageUrl="./delivery.png"
            />
          </>
        )}

        {myRole === "4" && (
          <>
            <MosaicOption
              url={"Service"}
              title={"Service"}
              imageUrl="./service-new.png"
            />
          </>
        )}

        <MosaicOption url={"/"} title={"Logout"} imageUrl="./door-logout.png" />
      </div>
    </>
  );
}

export default App;
