import { useState, useEffect } from "react";
import "./ModalTimeTracking.css";
import Modal from "react-responsive-modal";
import LoadingSpinner from "../loadingSpinner/Spinner";
import { IoCloseSharp } from "react-icons/io5";
import Select from "react-select";
import requester from "../rentals/requester";
import Swal from "sweetalert2";
import moment from "moment";

export default function ModalTimeTracking({ title, task, onSuccess }) {
  let consumer = JSON.parse(localStorage.getItem("consumer"));
  const envUrl = process.env.REACT_APP_MY_KEY_SERVER; //REACT_APP_MY_KEY_LOCAL - REACT_APP_MY_KEY_SERVER;
  const companyId = consumer.netsuiteInstance; //consumer.netsuiteInstance; - "TSTDRV2359357";

  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [serviceItems, setServiceItems] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [date, setDate] = useState(null);
  const [duration, setDuration] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const serviceItems = await postOrGetData("postObtainNetSuiteData", {
          companyId: companyId,
          endPoint: "obtainNetSuiteDataURL",
          typeOfConsult: 11,
        });

        const locations = await postOrGetData("postObtainNetSuiteData", {
          companyId: companyId,
          endPoint: "obtainNetSuiteDataURL",
          typeOfConsult: 12,
          deliveryId: consumer?.employeeId, //consumer?.employeeId, - 1655
        });

        if (serviceItems.length > 0) setServiceItems(serviceItems);
        else setServiceItems([]);

        if (locations.details.length > 0) setLocations(locations.details);
        else setLocations([]);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "An error occurred while loading the data",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    };

    fetchData();
  }, []);

  const saveTimeTracking = async () => {
    if (
      !selectedItem ||
      !selectedLocation ||
      !date ||
      !duration ||
      duration === 0
    ) {
      Swal.fire({
        icon: "warning",
        title: "Please fill all the fields",
        showConfirmButton: false,
        timer: 2000,
      });
      return;
    }

    try {
      setIsLoading(true);
      const formatDate = moment(date).format("MM/DD/YYYY");
      const obj = {
        companyId: companyId,
        endpoint: "postModuleTaskURL",
        type: "createTimeTracking",
        employeeId: consumer?.employeeId,
        childOpportunityId: task.unitDetailsObj.childOpportunityId,
        customerId: parseInt(task.customer),
        taskId: task.internalid,
        date: formatDate,
        duration: duration,
        locationId: selectedLocation,
        itemId: selectedItem,
      };

      const postNewTimeTracking = await postOrGetData("postModuleTask", obj);

      if (postNewTimeTracking.message === "SUCESS") {
        setIsLoading(false);
        Swal.fire({
          icon: "success",
          title: postNewTimeTracking.details,
          showConfirmButton: false,
          timer: 5000,
        });
        onSuccess(true);
        onCloseModal();
      } else {
        onSuccess(false);
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          title: "An error occurred while saving the time tracking",
          showConfirmButton: false,
          timer: 4000,
        });
      }
    } catch (error) {
      onSuccess(false);
      setIsLoading(false);
      Swal.fire({
        icon: "error",
        title: "An error occurred while saving the time tracking",
        showConfirmButton: false,
        timer: 4000,
      });
    }
  };

  const onOpenModal = async () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  const handleDurationChange = (e) => {
    const value = e.target.value;
    const regex = /^[0-9]*([.][0-9]*)?$/;

    // Verificar si el valor cumple con la expresión regular
    if (regex.test(value)) {
      // Si cumple, actualizar el estado
      setDuration(value);
    } else if (value === "" || regex.test(value.slice(0, -1))) {
      // Si el valor está vacío o el valor sin el último carácter cumple con la expresión regular,
      // actualizar el estado con el nuevo valor sin el último carácter inválido
      setDuration(value.slice(0, -1));
    }

    // Si el valor no cumple con la expresión regular y no es válido para ser corregido,
    // no realizar ninguna acción (no actualizar el estado)
  };

  const postOrGetData = async (endpoint, body) => {
    try {
      const res = await requester.dynamicRequest(
        `${envUrl}/${endpoint}`,
        "POST",
        body
      );
      return res.responseService ? res.responseService : res;
    } catch (error) {
      throw error;
    }
  };

  return (
    <>
      <button type="button" className="available_button" onClick={onOpenModal}>
        Time Tracking
      </button>

      <Modal
        open={open}
        center
        closeOnOverlayClick={false}
        classNames={{
          modal: "customModalTimeTracking",
        }}
      >
        {isLoading && <LoadingSpinner />}

        <div className="header_modal">
          <h3
            style={{
              marginRight: "1rem",
            }}
          >
            {title}
          </h3>
          <button onClick={onCloseModal} className="available_button">
            <IoCloseSharp fontSize={25} />
          </button>
        </div>
        <div className="container_time_tracking">
          <section className="form_input_separator">
            <div className="input_container_tracking">
              <label className="form-label">Employee</label>
              <input
                type="text"
                className="form-control"
                defaultValue={task.technicianName}
                readOnly
              />
            </div>

            <div className="input_container_tracking">
              <label className="form-label">Customer</label>
              <input
                type="text"
                className="form-control"
                defaultValue={task.customerName}
                readOnly
              />
            </div>
          </section>

          <section className="form_input_separator">
            <div className="input_container_tracking">
              <label className="form-label">Date</label>
              <input
                type="date"
                className="form-control"
                onChange={(e) => setDate(e.target.value)}
              />
            </div>

            <div className="input_container_tracking">
              <label className="form-label">Duration (Hours)</label>
              <input
                type="text" // Usamos 'text' en lugar de 'number' para poder aceptar puntos
                className="form-control"
                value={duration}
                onChange={handleDurationChange}
              />
            </div>
          </section>

          <section className="form_input_separator">
            <div className="input_container_tracking">
              <label className="form-label">Location</label>

              <Select
                options={locations.map((location) => ({
                  value: location.id,
                  label: location.name,
                }))}
                onChange={(selectedOption) =>
                  setSelectedLocation(selectedOption.value)
                }
              />
            </div>

            <div className="input_container_tracking">
              <label className="form-label">Service Item</label>

              <Select
                options={serviceItems.map((item) => ({
                  value: item.id,
                  label: item.itemid,
                }))}
                onChange={(selectedOption) =>
                  setSelectedItem(selectedOption.value)
                }
              />
            </div>
          </section>

          <section className="form_input_separator">
            <div className="input_container_tracking">
              <label className="form-label">Service Opportunity</label>
              <input
                type="text"
                className="form-control"
                defaultValue={task.servicecheckinname}
                readOnly
              />
            </div>

            <div className="input_container_tracking">
              <label className="form-label">Case Task Event</label>
              <input
                type="text"
                className="form-control"
                defaultValue={task.title}
                readOnly
              />
            </div>
          </section>

          <button
            type="button"
            className="available_button"
            style={{
              width: "100%",
            }}
            onClick={() => saveTimeTracking()}
          >
            Enter Time
          </button>
        </div>
      </Modal>
    </>
  );
}
