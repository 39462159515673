import { useEffect, useState } from "react";
import Header from "../../components/header/header";
import FloatingButton from "../../components/floatingButton/FloatingButton";
import DriversTable from "../../components/driversTable/DriversTable";
import requester from "../../components/rentals/requester";
import AccordionTable from "../../components/accordionTable/AccordionTable";
import LoadingSpinner from "../../components/loadingSpinner/Spinner";
import { MdModeEdit, MdOutlineDone } from "react-icons/md";
import CreateParentModal from "../../components/createParentModal/CreateParentModal";
import Swal from "sweetalert2";
import OpportunityDetailModal from "../../components/opportunityDetailModal/OpportunityDetailModal";
import { Link } from "react-router-dom";

export default function FieldService() {
  const [childs, setChilds] = useState([]);
  const [loadingChilds, setLoadingChilds] = useState(true);
  const [parents, setParents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [childsDataForParent, setChildsDataForParent] = useState([]);
  const [bannerMsg, setBannerMsg] = useState("");
  const [editableRow, setEditableRow] = useState(null);
  const [prevRate, setPrevRate] = useState("");
  const [parentId, setParentId] = useState("");
  const [technicianTasks, setTechnicianTasks] = useState([]);

  let consumer = JSON.parse(localStorage.getItem("consumer"));
  const myRole = consumer?.role; //  consumer?.role; o "1", "2", "4"
  const envUrl = process.env.REACT_APP_MY_KEY_SERVER; //REACT_APP_MY_KEY_LOCAL - REACT_APP_MY_KEY_SERVER;
  const companyId = consumer.netsuiteInstance; //consumer.netsuiteInstance; - "TSTDRV2359357";

  const columns = [
    {
      name: "Internal ID",
      selector: (row) => row.internalId,
      sortable: true,
    },
    {
      name: "Check-In #",
      selector: (row) => row.checkIn,
      sortable: true,
    },
    {
      name: "Customer",
      selector: (row) => row.customer,
      sortable: true,
    },
    {
      name: "Service Date",
      selector: (row) => row.serviceDate,
      sortable: true,
    },
    {
      name: "Date Created",
      selector: (row) => row.dateCreated,
      sortable: true,
    },
  ];

  const columnsTableTechnician = [
    {
      name: "Edit",
      selector: (row) => {
        return (
          <Link
            to={`/home/Service/task/${row.internalid}`}
            state={{
              title: row.title,
              task: row,
            }}
          >
            <button
              type="button"
              //classnamme depending on the status COMPLETE
              className={
                row.status === "COMPLETE"
                  ? "not_available_button"
                  : "available_button"
              }
              disabled={row.status === "COMPLETE" ? true : false}
            >
              Edit
            </button>
          </Link>
        );
      },
      sortable: true,
    },
    {
      name: "Service Type",
      selector: (row) => row.serviceType,
      sortable: true,
    },
    {
      name: "Scheduled Date (Start Date)",
      selector: (row) => row.startdate,
      sortable: true,
    },
    {
      name: "Company",
      selector: (row) => row.customerName,
      sortable: true,
    },
    {
      name: "Service Location",
      selector: (row) => row.address,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Task Title",
      selector: (row) => row.title,
      sortable: true,
    },
  ];

  const columnsChilds = [
    {
      name: "Item",
      selector: (row) => row.item,
      sortable: true,
    },
    {
      name: "Serial Number",
      selector: (row) => row.serialNumberName,
      sortable: true,
    },
    {
      name: "Quantity",
      selector: (row) => row.quantity,
      sortable: true,
    },
    {
      name: "Opportunity",
      selector: (row) => {
        return (
          <OpportunityDetailModal
            opportunity={row.columnopportunityid}
            row={row}
            parentId={parentId}
          />
        );
      },
      sortable: true,
    },
    {
      name: "Invoice Check In",
      selector: (row) => row.columninvoicename,
      sortable: true,
    },
    {
      name: "To Invoice",
      selector: (row) => row.columnchkinvoice,
      sortable: true,
      cell: (row) => {
        return (
          <input
            type="checkbox"
            defaultChecked={row.columnchkinvoice}
            onChange={() => {
              const updatedData = childsDataForParent.map((item) => {
                if (item.id === row.id) {
                  return { ...item, columnchkinvoice: !item.columnchkinvoice };
                }
                return item;
              });
              setChildsDataForParent(updatedData);

              handleClickCheckboxInvoice(updatedData, row.id);
            }}
            disabled={row.columninvoicename !== "" ? true : false}
          />
        );
      },
    },
    {
      name: "Service Rate",
      selector: (row) => row.rate,
      sortable: true,
      cell: (row, rowIndex) =>
        editableRow === rowIndex ? (
          <input
            className="form-control"
            type="text"
            value={childsDataForParent[rowIndex].rate}
            onChange={(e) => {
              const newData = [...childsDataForParent];
              newData[rowIndex].rate = e.target.value;
              setChildsDataForParent(newData);
            }}
          />
        ) : (
          row.rate
        ),
    },
    {
      name: "Action",
      accessor: "id",
      cell: (row, rowIndex) => {
        if (editableRow === rowIndex) {
          return (
            <button
              type="button"
              className="available_button"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "0.5rem",
              }}
              onClick={() => {
                handleSaveRate(rowIndex, row);
              }}
            >
              <span style={{ fontSize: "0.8rem" }}>Save</span>
              <MdOutlineDone fontSize={15} />
            </button>
          );
        } else {
          return (
            <button
              type="button"
              className="available_button"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "0.5rem",
              }}
              onClick={() => {
                const prevRate = childsDataForParent[rowIndex].rate;
                setPrevRate(prevRate);
                setEditableRow(rowIndex);
              }}
            >
              <span style={{ fontSize: "0.8rem" }}>Edit</span>
              <MdModeEdit fontSize={15} />
            </button>
          );
        }
      },
      width: "150px",
    },
  ];

  useEffect(() => {
    if (myRole === "4") loadTechnicianTasks();
    else if (myRole === "2" || myRole === "1") loadParents();
  }, []);

  const loadTechnicianTasks = async () => {
    const childsTechnician = await loadAndGetData("postObtainNetSuiteData", {
      companyId: companyId,
      endPoint: "postObtainNetSuiteData",
      typeOfConsult: 3,
      deliveryId: consumer?.employeeId,
    });

    if (childsTechnician.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Cannot find any task for this technician",
        timer: 3000,
      });
      setLoading(false);
      return;
    }

    setLoading(false);
    const tasksWithServiceType = [];
    childsTechnician.forEach((technician) => {
      technician.childOpportunities.forEach((childOpportunity) => {
        const address = childOpportunity.address;
        const servicenotes = childOpportunity.servicenotes;
        const lineTasks = childOpportunity.lineTasks;
        const lineItems = childOpportunity.lineItems;
        const serviceType = childOpportunity.jobtype.name;
        const technicianName = childOpportunity.objectTechnician[0].technician;
        const customerName = childOpportunity.customername;
        const servicecheckinname = childOpportunity.servicecheckinname;
        const unitDetailsObj = {
          stockItem: childOpportunity.customeritemname
            ? childOpportunity.customeritemname
            : "",
          customerSerial: childOpportunity.customerserialname
            ? childOpportunity.customerserialname
            : "",
          makemodel: childOpportunity.makemodel
            ? childOpportunity.makemodel
            : "",
          description: childOpportunity.description
            ? childOpportunity.description
            : "",
          servicerequest: childOpportunity.servicerequest
            ? childOpportunity.servicerequest
            : "",
          childOpportunityId: childOpportunity.servicecheckinid
            ? childOpportunity.servicecheckinid
            : "",
        };
        lineTasks.forEach((lineTask) => {
          const taskWithServiceType = {
            ...lineTask,
            unitDetailsObj,
            lineItems,
            serviceType,
            customerName,
            servicenotes,
            servicecheckinname,
            technicianName,
            address,
          };
          tasksWithServiceType.push(taskWithServiceType);
        });
      });
    });
    setTechnicianTasks(tasksWithServiceType);
  };

  const handleLoadOportunities = async (opportunityId) => {
    setParentId(opportunityId);
    // Buscar el parentOpportunityName con el opportunityId
    const parent = parents.find(
      (parent) => parent.parentOpportunityId === opportunityId
    );

    setLoading(true);
    setBannerMsg(`Childs - Parent Opportunity ${parent.parentOpportunityName}`);

    const opportunities = await loadAndGetData("postObtainNetSuiteData", {
      companyId: companyId, //"TSTDRV2359357",
      endPoint: "obtainNetSuiteDataURL",
      typeOfConsult: 7,
      parentCheckIn: opportunityId,
      isParent: true,
    });

    if (opportunities.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Childs not found for this parent.",
        timer: 3000,
      });

      setLoading(false);
      return;
    }

    const lineItems = opportunities[0].lineItems;
    let itemIdCounter = 1; // Variable para generar IDs incrementales

    const data = lineItems.map((item) => {
      let toInvoice = item.columnchkinvoice;
      toInvoice === "T" ? (toInvoice = true) : (toInvoice = false);

      const dataItem = {
        id: itemIdCounter, // Asignar el ID incrementado
        itemId: item.item,
        item: item.itemname,
        serialNumber: item.serialnumberid,
        serialNumberName: item.serialnumbername,
        quantity: item.quantity,
        rate: item.rate,
        columnopportunityid: item.columnopportunityid,
        line: item.line,
        opportunityParentId: opportunityId,
        columnchkinvoice: toInvoice,
        columninvoiceid: item.columninvoiceid,
        columnopportunityid: item.columnopportunityid,
        columninvoicename: item.columninvoicename,
        columnopportunityname: item.columnopportunityname,
      };

      itemIdCounter++; // Incrementar el contador de IDs

      return dataItem;
    });

    setChildsDataForParent(data);
    setLoading(false);
  };

  const handleCreateChilds = async (opportunityId) => {
    setLoading(true);
    requester
      .dynamicRequest(`${envUrl}/postModuleCreateRecords`, "POST", {
        companyId: companyId,
        endpoint: "postModuleCreateRecordsURL",
        type: "createopportunity",
        oppId: opportunityId,
      })
      .then((res) => {
        if (res.message === "SUCCESS") {
          mostrarToast(
            "success",
            "Created successfully, we will update your data, please wait a moment.",
            5000
          );
          loadParents();
          return;
        }
        mostrarToast("error", "Error creating childs, try again.");
      })
      .catch((err) => {
        setLoading(false);
        mostrarToast("error", `Error creating childs: ${err.message}`);
      });
  };

  const handleSaveRate = async (rowIndex, rowData) => {
    // Obtener los datos necesarios de la fila
    const { columnopportunityid, line } = rowData;

    // Obtener el nuevo rate de la fila
    const newRate = childsDataForParent[rowIndex].rate;

    // Verificar si el rate ha sido modificado
    if (newRate === prevRate) {
      // No se ha modificado, realizar las acciones necesarias sin llamar a handleRateChange
      setEditableRow(null);
      return;
    }

    // Llamar a handleRateChange con el índice de la fila, los datos y el valor anterior
    const success = await handleRateChange(
      rowIndex,
      columnopportunityid,
      line,
      newRate
    );

    // Restablecer el estado de edición solo si handleRateChange fue exitoso
    if (success) {
      setEditableRow(null);
    }
  };

  const handleRateChange = async (
    rowIndex,
    columnopportunityid,
    line,
    newRate
  ) => {
    if (newRate === "") return false;

    try {
      setLoading(true);
      // Realizar la solicitud para establecer el nuevo valor en NetSuite
      const saveNewRate = await loadAndGetData("postModuleCreateRecords", {
        companyId: companyId,
        endpoint: "postModuleCreateRecordsURL",
        type: "updaterate",
        oppId: columnopportunityid,
        line: line,
        rate: newRate,
      });

      // Una vez que la respuesta sea exitosa, obtener nuevamente las oportunidades para actualizar la tabla
      if (saveNewRate.message === "SUCCESS") {
        mostrarToast("success", "Rate updated successfully.");

        // Actualizar el valor del rate en childsDataForParent
        const newData = [...childsDataForParent];
        newData[rowIndex].rate = newRate;
        setChildsDataForParent(newData);
        //limpiar el prevRate
        setPrevRate("");
        setLoading(false);
        return true;
      } else {
        mostrarToast("error", "Error updating rate, try again.");
        // //limpiar el valor del input antes de quitar el edit mode
        const newData = [...childsDataForParent];
        newData[rowIndex].rate = prevRate;
        setChildsDataForParent(newData);
        // Quitar el edit mode
        setEditableRow(null);
        setLoading(false);
        return false;
      }
    } catch (error) {
      mostrarToast("error", error.message);
      setLoading(false);
      return false;
    }
  };

  const handleCreateOpportunities = async () => {
    setLoading(true);
    const opportunities = await loadAndGetData("postModuleCreateRecords", {
      companyId: companyId,
      endpoint: "postModuleCreateRecordsURL",
      type: "createopportunity",
      oppId: parentId,
    });

    if (opportunities.message === "SUCCESS") {
      mostrarToast(
        "success",
        "Opportunity created successfully, we will update your data, please wait a moment."
      );
      reloadTableData();
      return;
    }
    mostrarToast("error", "Error creating opportunity, try again.");
    setLoading(false);
  };

  const handleClickCheckboxInvoice = async (data, rowId) => {
    const row = data.find((item) => item.id === rowId);

    setLoading(true);
    const checkboxResult = await loadAndGetData("postModuleCreateRecords", {
      companyId: companyId,
      endpoint: "postModuleCreateRecordsURL",
      type: "updatecheckbox",
      oppId: parentId,
      line: row.line,
      checkbox: row.columnchkinvoice,
    });

    if (checkboxResult.message === "SUCCESS") {
      mostrarToast(
        "success",
        "We will update your data, please wait a moment."
      );
      reloadTableData();
      return;
    }
    mostrarToast("error", "Error updating to invoice, try again.");
    setLoading(false);
  };

  const handleCheckInInvoice = async () => {
    //de la lista de childsDataParent necesito obtener todos los que tengan el checkbox en true y que
    //no tengan un valor en la columna columninvoicename
    const data = childsDataForParent.filter(
      (item) => item.columnchkinvoice === true && item.columninvoicename === ""
    );

    if (data.length === 0) {
      mostrarToast("error", "No items to invoice.");
      return;
    }

    setLoading(true);
    const opportunities = await loadAndGetData("postModuleCreateRecords", {
      companyId: companyId,
      endpoint: "postModuleCreateRecordsURL",
      type: "createchkinvoice",
      oppId: parentId,
    });

    if (opportunities.message === "SUCCESS") {
      mostrarToast(
        "success",
        "Invoice created successfully, we will update your data, please wait a moment."
      );
      reloadTableData();
      return;
    }

    mostrarToast("error", "Error creating invoice, try again.");
    setLoading(false);
  };

  const handleReloadParentOnSucces = ({ success, parentCheckInName }) => {
    if (success) {
      setLoading(true);
      Swal.fire({
        icon: "success",
        title: `Parent: ${parentCheckInName} created successfully, we will update your data, please wait a moment`,
        timer: 5000,
      });
      loadParents();
    }
  };

  const reloadTableData = async () => {
    setLoading(true);
    const opportunities = await loadAndGetData("postObtainNetSuiteData", {
      companyId: companyId,
      endPoint: "obtainNetSuiteDataURL",
      typeOfConsult: 7,
      parentCheckIn: parentId,
      isParent: true,
    });

    if (opportunities.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Childs not found for this parent.",
        timer: 3000,
      });
      setLoading(false);
      return;
    }

    const lineItems = opportunities[0].lineItems;
    const data = lineItems.map((item) => {
      let toInvoice = item.columnchkinvoice;
      toInvoice === "T" ? (toInvoice = true) : (toInvoice = false);

      return {
        itemId: item.item,
        item: item.itemname,
        serialNumber: item.serialnumberid,
        serialNumberName: item.serialnumbername,
        quantity: item.quantity,
        rate: item.rate,
        columnopportunityid: item.columnopportunityid,
        line: item.line,
        opportunityParentId: parentId,
        columnchkinvoice: toInvoice,
        columninvoiceid: item.columninvoiceid,
        columnopportunityid: item.columnopportunityid,
        columninvoicename: item.columninvoicename,
        columnopportunityname: item.columnopportunityname,
      };
    });

    setChildsDataForParent(data);
    setLoading(false);
  };

  const loadParents = async () => {
    requester
      .dynamicRequest(`${envUrl}/postObtainNetSuiteData`, "POST", {
        companyId: companyId,
        endPoint: "postObtainNetSuiteData",
        typeOfConsult: 3,
        deliveryId: consumer.employeeId, //"4856" - consumer.employeeId
      })
      .then((res) => {
        if (res === undefined) {
          mostrarToast("error", "Error loading parents.");
          setLoading(false);
          return;
        }
        const childOpportunities = res.responseService
          .map((parent) => parent.childOpportunities)
          .flat();

        const data = childOpportunities.map((child) => {
          return {
            internalId: child.servicecheckinid,
            checkIn: child.servicecheckinname,
            customer: child.customername,
            serviceDate: child.servicedate,
            dateCreated: child.createddate,
          };
        });

        setParents(res.responseService);

        setChilds(data);
        setLoadingChilds(false);
        setLoading(false);
      });
  };

  const loadAndGetData = async (endpoint, body) => {
    try {
      const res = await requester.dynamicRequest(
        `${envUrl}/${endpoint}`,
        "POST",
        body
      );
      return res.responseService ? res.responseService : res;
    } catch (error) {
      throw error;
    }
  };

  function mostrarToast(icono, titulo, timer) {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: timer || 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: icono,
      title: titulo,
    });
  }

  return (
    <>
      <Header />

      {loading && <LoadingSpinner />}

      {myRole === "2" ? ( //2 = driver
        <DriversTable
          columns={columns}
          data={childs}
          loading={loadingChilds}
          needBanner={true}
          bannerText={"Childs - Check In"}
        />
      ) : myRole === "1" ? ( //1 = admin
        <div
          className="parents_container"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <section className="parent_header">
            <CreateParentModal
              onOpenModal={true}
              onSuccessParent={handleReloadParentOnSucces}
            />

            <input
              className="form-control"
              type="text"
              placeholder="Search example: OPP000187..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                marginBottom: "1rem",
              }}
            />
          </section>

          <div
            className="parents_accordions"
            style={{
              marginBottom: "1rem",
            }}
          >
            {parents
              .filter((parent) =>
                parent.parentOpportunityName
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())
              )
              .map((parent) => (
                <AccordionTable
                  key={parent.parentOpportunityId}
                  parentNumber={parent.parentOpportunityName}
                  parentId={parent.parentOpportunityId}
                  childs={parent.childOpportunities}
                  onCreateOpportunity={handleLoadOportunities}
                  onCreateChilds={handleCreateChilds}
                />
              ))}
          </div>

          {childsDataForParent.length > 0 && (
            <>
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  padding: "0 1rem",
                }}
              >
                {/* Temp removed */}
                {/* <button
                  type="button"
                  className="btn_create_item"
                  style={{
                    width: "100%",
                    height: "max-content",
                  }}
                  onClick={() => handleCreateOpportunities()}
                  disabled={childsDataForParent.every(
                    (item) => item.columnopportunityname !== ""
                  )}
                >
                  Create Opportunity
                </button> */}
                <button
                  type="button"
                  className="btn_create_item"
                  style={{
                    width: "100%",
                    height: "max-content",
                  }}
                  disabled={childsDataForParent.every(
                    (item) => item.columninvoicename !== ""
                  )}
                  onClick={() => handleCheckInInvoice()}
                >
                  Check In - Invoice
                </button>
              </div>

              <DriversTable
                columns={columnsChilds}
                data={childsDataForParent}
                needBanner={true}
                bannerText={bannerMsg}
              />
            </>
          )}
        </div>
      ) : (
        //4 - technician
        <>
          <DriversTable
            columns={columnsTableTechnician}
            data={technicianTasks}
            needBanner={true}
            needSearch={true}
            bannerText={"Tasks"}
          />
        </>
      )}

      <FloatingButton url="/home" />
    </>
  );
}
