import { useState, useCallback, useRef, useEffect } from "react";
import Webcam from "react-webcam";
import "./ReactWebCam.css";
import { MdOutlineCamera } from "react-icons/md";
import { HiOutlineArrowSmLeft, HiOutlineArrowSmRight } from "react-icons/hi";
import { FiCamera } from "react-icons/fi";
import { AiOutlineUpload } from "react-icons/ai";
import Swal from "sweetalert2";

export default function ReactWebCam(props) {
  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [open, setOpen] = useState(false);

  const webcamRef = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    props.sendBackPhotoArray(images);
  }, [images]);

  const openCamera = () => {
    setOpen(true);
    getDevices();
  };
  const getDevices = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      stream.getTracks().forEach((track) => track.stop());
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );
      setDevices(videoDevices);
    } catch (error) {
      console.error("Error al obtener la lista de dispositivos: ", error);
    }
  };

  const capture = () => {
    navigator.permissions.query({ name: "camera" }).then((result) => {
      if (result.state === "denied") {
        Swal.fire({
          icon: "warning",
          title: "You need to allow the camera permission to take a picture",
          timer: 2000,
        });
      } else {
        const imageSrc = webcamRef.current.getScreenshot();
        setImages((prevImages) => [...prevImages, imageSrc]);
        setCurrentIndex(images.length);
      }
    });
  };

  const handleCameraChange = useCallback((event) => {
    setSelectedDevice(event.target.value);
  }, []);

  const handlePrevious = () => {
    if (currentIndex !== null && currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    if (currentIndex !== null && currentIndex < images.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handleDelete = () => {
    setImages(images.filter((image, index) => index !== currentIndex));
    setCurrentIndex(Math.max(0, currentIndex - 1));
  };

  const handleFileUpload = (event) => {
    //check if the file size is bigger than 5MB
    if (!checkFileSize()) return;

    const collapseCamera = document.getElementById("collapseCamera");
    if (!collapseCamera.classList.contains("show")) {
      collapseCamera.classList.add("show");
    }

    const fileList = event.target.files;

    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      const extension = file.name.split(".").pop().toLowerCase();
      if (["png", "jpeg", "jpg"].indexOf(extension) === -1) {
        //check if the collapseCamera is open, if is close it
        if (collapseCamera.classList.contains("show")) {
          collapseCamera.classList.remove("show");
        }
        //if the file extension is not valid, show an error message
        Swal.fire({
          icon: "warning",
          title:
            "Invalid file type. Only PNG, JPEG, and JPG files are allowed.",
          timer: 2500,
        });

        continue;
      }

      //check if the collapseCamera is open, if not, open it
      const reader = new FileReader();

      reader.onload = () => {
        setImages([...images, reader.result]);
        setCurrentIndex(images.length);
      };
      reader.readAsDataURL(file);
    }
  };

  function checkFileSize() {
    let input = document.querySelector('input[type="file"]');
    let file = input.files[0];
    let maxSize = 5500000;
    if (file && file.size > maxSize) {
      Swal.fire({
        icon: "warning",
        title: "File size is too big!",
        text: "Maximum file size is 5MB",
        timer: 2000,
      });
      resetFileInput();

      return false;
    }
    return true;
  }

  function resetFileInput() {
    document.querySelector('input[type="file"]').value = null;
  }

  return (
    <>
      <div className="color-separate d-flex justify-content-between">
        <div className="container">
          <h1>Photos</h1>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          gap: "0.5rem",
        }}
      >
        <button
          className="opening_camera_buttons"
          type="button"
          onClick={() => openCamera()}
          data-bs-toggle="collapse"
          href="#collapseCamera"
        >
          <FiCamera fontSize={40} />
        </button>

        <button
          className="upload-container opening_camera_buttons"
          type="button"
        >
          <input
            type="file"
            accept="image/png, image/jpeg, image/jpg"
            multiple
            ref={fileInputRef}
            onChange={handleFileUpload}
            id="file-input"
          />
          <label htmlFor="file-input">
            <AiOutlineUpload fontSize={40} />
          </label>
        </button>
      </div>

      <div className="collapse" id="collapseCamera">
        <div className="card card-body">
          <section>
            {open && (
              <>
                <div
                  style={{
                    padding: "1rem 0",
                  }}
                >
                  <label
                    htmlFor="camera-select"
                    style={{
                      marginRight: "0.5rem",
                    }}
                  >
                    <strong>Select camera:</strong>
                  </label>
                  <select
                    id="camera-select"
                    defaultValue={
                      devices.length > 0 ? devices[0].deviceId : null
                    }
                    onChange={handleCameraChange}
                  >
                    {devices.map((device) => (
                      <option key={device.deviceId} value={device.deviceId}>
                        {device.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Webcam
                    className="camera_dynamic_width"
                    style={{
                      backgroundColor: "black",
                    }}
                    audio={false}
                    ref={webcamRef}
                    videoConstraints={{
                      deviceId: selectedDevice || undefined,
                      facingMode: "environment",
                      width: 350,
                      height: 300,
                    }}
                  />
                </div>

                {
                  devices.length !== 0 && (
                    <button
                    type="button"
                    className="camera_controls_style"
                    onClick={capture}
                  >
                    Take Picture <MdOutlineCamera fontSize={30} color="inherit" />
                  </button>   
                  )
                }
              </>
            )}
          </section>
          <section>
            {images.length > 0 && (
              <>
                <div
                  style={{
                    padding: "1rem 0",
                  }}
                >
                  <strong>
                    Image {currentIndex + 1} of {images.length}
                  </strong>
                </div>
                <div>
                  <img
                    className="camera_gallery_controls"
                    src={images[currentIndex]}
                  />
                </div>
                <div className="container_gallery_controls">
                  <button
                    type="button"
                    onClick={handlePrevious}
                    className="camera_controls_style"
                  >
                    <HiOutlineArrowSmLeft fontSize={30} color="inherit" />
                    Previous
                  </button>
                  <button
                    type="button"
                    onClick={handleDelete}
                    className="camera_controls_style delete_button"
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    onClick={handleNext}
                    className="camera_controls_style"
                  >
                    Next <HiOutlineArrowSmRight fontSize={30} color="inherit" />
                  </button>
                </div>
              </>
            )}
          </section>
        </div>
      </div>
    </>
  );
}
