import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import SignaturePad from "react-signature-canvas";
import LoadingSpinner from "../loadingSpinner/Spinner";
import requester from "./requester.js";
import Swal from "sweetalert2";
import ErrorBoundary from "../../ErrorBoundary";
import { AiFillDelete } from "react-icons/ai";
import FloatingButton from "../floatingButton/FloatingButton";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import DataTable from "react-data-table-component";
import "./PickupOrReturn.css";
import ReactWebCam from "../ReactWebCam/ReactWebCam";

const BoundaryComponent = () => {
  const [saved, setSaved] = useState(false);
  const [loading, setLoading] = useState(true);
  const [rentalInformation, initRentalInformation] = useState([]);
  const [rentalLocations, setRentalLocations] = useState([]);
  const [returnLocations, setReturnLocations] = useState([]);
  const [images, setImages] = useState([]);
  const [selectedValues, setSelectedValues] = useState({});

  const envUrl = process.env.REACT_APP_MY_KEY_SERVER; //REACT_APP_MY_KEY_LOCAL - REACT_APP_MY_KEY_SERVER;

  const rental = useLocation();
  const buttonRef = useRef();
  let signPad = useRef({});
  let signPad64 = "";

  let consumer = JSON.parse(localStorage.getItem("consumer"));

  const {
    hardwareForPickup,
    hardwareForReturn,
    type,
    reservation,
    locationName,
    location: locationForReturn,
  } = rental.state;

  const checkListItems = [
    {
      id: 1,
      description: "All items have been inspected for damage",
    },
    {
      id: 2,
      description: "All batteries have been removed from items",
    },
    {
      id: 3,
      description: "Customer signed return inspection form",
    },
  ];

  const columns = [
    {
      name: "Item",
      selector: (row) => row.itemName,
      sortable: true,
    },
    {
      name: "Quantity",
      selector: (row) => row.quantity,
      sortable: true,
    },
    {
      name: "Hardware Record",
      selector: (row) => row.hardwareName,
      sortable: true,
    },
    {
      name: "Serial",
      selector: (row) => row.hardwareName,
      sortable: true,
    },
    {
      name: "Rental Location",
      selector: (row) => (
        <select
          className="renLocationSelect custom_style_select"
          id={`rental${row.id}`}
          disabled={true}
        >
          <option
            value={type === "Pickup" ? row.defaultLocation : row.location}
            key={location.id}
          >
            {type === "Pickup" ? row.defaultLocationName : row.locationName}
          </option>
        </select>
      ),
      sortable: true,
      width: "250px",
    },
    {
      name: "Return Location",
      selector: (row) => {
        return (
          <select
            className="renLocationSelect custom_style_select"
            id={`return${row.id}`}
            disabled={type === "Pickup"}
            value={selectedValues[row.id] || row.defaultLocation || ""}
            onChange={(event) => handleReturnLocationChange(event, row)}
          >
            {returnLocations.map((location) => (
              <option value={location.id} key={location.id}>
                {location.name}
              </option>
            ))}
          </select>
        );
      },
      sortable: true,
      width: "230px",
    },
    {
      name: "Transfer To Bin",
      selector: () => "-",
      sortable: true,
    },
    {
      name: "Action",
      accessor: "id",
      cell: ({ id }) => (
        <button
          type="button"
          onClick={() => handleDelete(id)}
          className="delete-button"
        >
          Delete
        </button>
      ),
    },
  ];

  useEffect(() => {
    obtainRentalInfo()
      .then((res) => {
        setLoading(false);
        setRentalLocations(res.rentalLocations);
        setReturnLocations(res.returnLocations);
        let idCounter = 1;
        let mappedItems = res.items.map((item) => {
          const newItem = {
            id: idCounter,
            ...item,
            rentallocation: "",
            returnlocation: "",
          };

          idCounter++; // increment the id counter for the next item
          return newItem;
        });

        initRentalInformation(mappedItems);
      })
      .catch((e) => {
        alert(e.message);
      });
  }, []);

  const handleReturnLocationChange = (event, row) => {
    const newSelectedValues = { ...selectedValues };
    newSelectedValues[row.id] = event.target.value;
    setSelectedValues(newSelectedValues);
  };

  const clearSignature = () => {
    signPad.current.clear();
    var base64 = document.getElementById("base64_signature");
    base64.value = "";
    setSaved(false);
  };

  const saveSignature = () => {
    if (!signPad.current.isEmpty()) {
      setSaved(true);
      signPad64 = signPad.current.toDataURL();
      var base64 = document.getElementById("base64_signature");
      base64.value = signPad64;
    }
  };

  const obtainRentalInfo = async () => {
    let data = type == "Pickup" ? hardwareForPickup : hardwareForReturn;
    let obtInfo = await requester.dynamicRequest(
      `${envUrl}/rentalsInfo`,
      "POST",
      {
        companyId: consumer.netsuiteInstance,
        endpoint: "rentalsInfo",
        hardwareArray: data,
      }
    );
    return obtInfo;
  };

  const handleDelete = (id) => {
    initRentalInformation((prevData) => prevData.filter((d) => d.id !== id));
  };

  const receivePhotos = (photos) => {
    let convertedPhotos = photos.map((photo) => {
      return new Promise((resolve, _reject) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);
          const pngBase64 = canvas.toDataURL("image/png");
          resolve(pngBase64);
        };
        img.src = photo;
      });
    });

    Promise.all(convertedPhotos)
      .then((pngImages) => {
        setImages(pngImages);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const createTransaction = async () => {
    var signature = document.getElementById("base64_signature").value;
    if (!signature) {
      Swal.fire({
        icon: "warning",
        title: "Please add and save a signature!",
        timer: 2000,
      });
      return;
    }

    //get all the checkboxes and labels
    const htmlCheckboxes = document.querySelectorAll("input#checklistItem");
    const htmlLabelCheckboxes = document.querySelectorAll(
      "label#checklistItem"
    );
    const checklist_items = [];
    htmlCheckboxes.forEach((checkbox, index) => {
      checklist_items.push({
        checked: checkbox.checked,
        item: htmlLabelCheckboxes[index].innerText,
      });
    });

    const item = [];
    rentalInformation.forEach((rental) => {
      let rentalSelect = document.getElementById(`rental${rental.id}`);
      rentalSelect = rentalSelect.options[rentalSelect.selectedIndex].value;
      let returnSelect = document.getElementById(`return${rental.id}`);
      returnSelect = returnSelect.options[returnSelect.selectedIndex].value;

      item.push({
        id: rental.id,
        hardware: rental.hardwareId,
        item: rental.itemId,
        quantity: rental.quantity,
        rentallocation: rental.rentallocation
          ? rental.rentallocation
          : rentalSelect,
        returnlocation: rental.returnlocation
          ? rental.returnlocation
          : returnSelect,
        sn: rental.hardwareName,
        transferbin: "",
      });
    });

    setLoading(true);
    buttonRef.current.disabled = true;

    requester
      .dynamicRequest(`${envUrl}/postPickOrReturn`, "POST", {
        companyId: consumer.netsuiteInstance,
        endpoint: "postPickOrReturn",
        buildhtml: { checklist_items },
        custrecord_phq_eqr_return_reservation: reservation,
        custrecord_phq_eqr_pickup_reservation: reservation,
        images,
        item,
        name: reservation,
        off_rent: document.getElementById("off_rent").checked,
        reservation: reservation,
        signature: document.getElementById("base64_signature").value,
        type,
      })
      .then((res) => {
        if (!res) {
          setLoading(false);
          buttonRef.current.disabled = false;
          Swal.fire({
            title: "Error!",
            text: "Pick up could not be created!",
            icon: "error",
            confirmButtonText: "Ok",
            timer: 5000,
          });

          return;
        }

        setLoading(false);
        Swal.fire({
          title: res.message + ", ID: " + res.id,
          text: "You will be redirected to the rentals page in a moment!",
          icon: "success",
          confirmButtonText: "Ok",
          timer: 4000,
        });

        setTimeout(() => {
          window.location.href = "/home/Rentals";
        }, 4000);
      });
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      <form className="container">
        <div className="d-flex flex-column">
          <h4></h4>
          <div className="newRentalControl d-flex flex-row">
            <div className="col-12">
              <h1 className="mt-3">
                Rental {type} - Reservation {reservation}
              </h1>
            </div>
          </div>
          <div className="new-newRentalControl d-flex flex-row">
            <div className="">
              <div className="form-check">
                {type === "Return" ? (
                  <>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="off_rent"
                    />
                    <label className="form-check-label" htmlFor="off_rent">
                      Off Rent
                    </label>
                  </>
                ) : (
                  <>
                    <input
                      className="form-check-input"
                      type="hidden"
                      id="off_rent"
                    />
                  </>
                )}
              </div>
            </div>
          </div>

          {/* Checklist section */}
          <div className="d-flex flex-column">
            <table className="table" id="checklistItem">
              <thead>
                <tr className="color-separate">
                  <th scope="col">Select</th>
                  <th scope="col">Checklist item </th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {checkListItems.map((item) => (
                  <tr key={item.id}>
                    <th scope="row">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="checklistItem"
                      />
                    </th>
                    <td>
                      <label
                        className="form-check-label"
                        htmlFor="checklistItem"
                        id="checklistItem"
                      >
                        {item.description}
                      </label>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* React table */}
          <div className="container mt-4 mb-4" id="pickupRetTable">
            <DataTable
              className="dataTable"
              columns={columns}
              theme="solarized"
              highlightOnHover
              selectedRows
              responsive
              data={rentalInformation}
              fixedHeader
              fixedHeaderScrollHeight="500px"
            />
          </div>

          {/* React mobile table */}
          <div className="accordion mb-4" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  Rental Items <i className="bi bi-chevron-down"></i>
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div
                  className="accordion-body"
                  style={
                    rentalInformation.length > 1
                      ? { maxHeight: "100vh", overflow: "scroll" }
                      : { maxHeight: "60vh", overflow: "scroll" }
                  }
                >
                  {/* bootstrap card with the information of the card */}
                  <div className="container">
                    <div className="row">
                      {rentalInformation.map((rental) => (
                        <div
                          className="col-12 col-md-6 col-lg-4"
                          key={rental.hardwareName}
                        >
                          <div className="card mb-4">
                            <div className="card-body">
                              {/* Create absolute button to delete */}
                              <button
                                type="button"
                                className="btn btn-sm"
                                onClick={() => handleDelete(rental.id)}
                                style={{
                                  position: "absolute",
                                  right: "10px",
                                  top: "10px",
                                }}
                              >
                                <AiFillDelete fontSize={25} color={"red"} />
                              </button>

                              <h5 className="card-title mb-3">
                                Rental: {rental.hardwareName}
                              </h5>
                              <p className="card-text">
                                <b>Item:</b> {rental.itemName}
                              </p>
                              <p className="card-text">
                                <b>Quantity:</b> {rental.quantity}
                              </p>
                              <p className="card-text">
                                <b>Rental Location:</b>{" "}
                                <select
                                  className="renLocationSelect custom_style_select"
                                  id={`rental${rental.id}`}
                                  disabled={type === "Return" ? true : false}
                                >
                                  {rentalLocations.map((location) => (
                                    <option
                                      value={
                                        type === "Return"
                                          ? locationForReturn
                                          : location.id
                                      }
                                      key={location.id}
                                    >
                                      {type === "Return"
                                        ? locationName
                                        : location.name}
                                    </option>
                                  ))}
                                </select>
                              </p>
                              <p className="card-text">
                                <b>Return Location:</b>{" "}
                                <select
                                  className="renLocationSelect custom_style_select"
                                  id={`return${rental.id}`}
                                  disabled={type === "Pickup" ? true : false}
                                >
                                  {returnLocations.map((location) => (
                                    <option
                                      value={location.id}
                                      key={location.id}
                                    >
                                      {location.name}
                                    </option>
                                  ))}
                                </select>
                              </p>

                              <p className="card-text">
                                <b>SN:</b> {rental.hardwareName}
                              </p>

                              <p className="card-text">
                                <b>Transferbin:</b> {rental.transferbin}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Camera section */}
          <ReactWebCam sendBackPhotoArray={receivePhotos} />

          {/* Signature section */}
          <section className="signature-container">
            <h5>Signature</h5>
            <div className="d-flex flex-column justify-content-center mx-auto mt-3">
              <div className="p-2 signature img-fluid img-thumbnail custom-size">
                <SignaturePad
                  canvasProps={{
                    width: 1500,
                    height: 200,
                  }}
                  ref={signPad}
                />
              </div>
              <p></p>
              <div className="d-flex justify-content-between">
                <div className="container">
                  <button
                    type="button"
                    className="delete-button custom-size"
                    data-action="clear"
                    onClick={clearSignature}
                  >
                    Clear
                  </button>
                </div>
                <div className="container">
                  <button
                    type="button"
                    className="available_button custom-size custom-loader"
                    data-action="clear"
                    onClick={saveSignature}
                  >
                    Save &nbsp;
                    {saved && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-check2 white font-weight-bold"
                      >
                        <path
                          fillRule="evenodd"
                          d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L6.5 10.793l6.646-6.647a.5.5 0 0 1 .708 0z"
                        />
                      </svg>
                    )}
                  </button>
                </div>
              </div>
            </div>

            <div className="container">
              <input type="text" id="base64_signature" hidden></input>
            </div>
          </section>
        </div>

        <br />

        <div className="container">
          <button
            type="button"
            className="available_button w-100 mb-5"
            ref={buttonRef}
            onClick={() => createTransaction()}
          >
            {type === "Pickup" ? "Create Pick Up" : "Create Return"}
          </button>
        </div>

        <FloatingButton url={"/home/Rentals"} logout={false} />
      </form>
    </>
  );
};

const PickedItem = () => {
  return (
    <>
      <ErrorBoundary>
        <BoundaryComponent />
      </ErrorBoundary>
    </>
  );
};

export default PickedItem;
