import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ConnectionProvider } from "./Hooks/ConnectionContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ConnectionProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ConnectionProvider>
);

reportWebVitals();
