import React from "react";

/* Boundaries are only designed for intercepting errors that originate from 3 places in a React component:
- During render phase
- In a lifecycle method
- In the constructor
*/

/* Error boundaries do not catch errors for:
- Event handlers (learn more)
- Asynchronous code (e.g. setTimeout or requestAnimationFrame callbacks)
- Server side rendering
- Errors thrown in the error boundary itself (rather than its children)
*/

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { tieneError: false, mensajeError: "" };
  }

  static getDerivedStateFromError(error) {
    return { tieneError: true, mensajeError: error.message };
  }

  render() {
    if (this.state.tieneError) {
      return (
        <div className="error-overlay">
          <h5>
            An error has occurred, please contact an administrator to solve the
            problem:
          </h5>
          <p> {this.state.mensajeError} </p>
          <button
            className="error-overlay-button"
            onClick={() => {
              window.location.href = "/home";
            }}
          >
            Redirect To Home
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
