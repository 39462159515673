import React, { useEffect, useState } from "react";
import "./CreateParentModal.css";
import { IoCloseSharp } from "react-icons/io5";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import LoadingSpinner from "../loadingSpinner/Spinner";
import DriversTable from "../driversTable/DriversTable";
import Swal from "sweetalert2";
import requester from "../rentals/requester";
import moment from "moment/moment";
import Select from "react-select";
import { v4 as uuid } from "uuid";

export default function CreateParentModal({ onSuccessParent }) {
  const [open, setOpen] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [technicians, setTechnicians] = useState([]);
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");
  const [selectedItemUID, setSelectedItemUID] = useState("");
  const [counter, setCounter] = useState(0);
  const [selectedTechnicians, setSelectedTechnicians] = useState([]);

  let consumer = JSON.parse(localStorage.getItem("consumer"));
  const envUrl = process.env.REACT_APP_MY_KEY_SERVER; //REACT_APP_MY_KEY_LOCAL - REACT_APP_MY_KEY_SERVER;
  const companyId = consumer.netsuiteInstance; //consumer.netsuiteInstance; - "TSTDRV2359357";

  const columns = [
    {
      name: "Id",
      selector: (row) => row.uniqueId,
      sortable: true,
      omit: true,
    },
    {
      name: "Item",
      selector: (row) => {
        return (
          <select
            className="form-select"
            defaultValue={row.item}
            onChange={(e) => handleItemSelection(row, e)}
          >
            <option value="">Select an item</option>
            {items.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </select>
        );
      },
      width: "200px",
    },
    {
      name: "Serial Numbers",
      selector: (row) => {
        return (
          <select
            className="form-select"
            value={row.serialNumber}
            onChange={(e) => {
              const updatedData = tableData.map((dataRow) => {
                if (dataRow === row) {
                  return { ...dataRow, serialNumber: e.target.value };
                }
                return dataRow;
              });
              setTableData(updatedData);
            }}
          >
            <option value="">Select a serial number</option>
            {row.serialNumbers.map((serialNumber) => (
              <option
                key={serialNumber.precisionAssetId}
                value={serialNumber.precisionAssetId}
              >
                {serialNumber.precisionAssetName}
              </option>
            ))}
          </select>
        );
      },
      width: "200px",
    },
    {
      name: "Quantity",
      selector: (row) => {
        return (
          <input
            type="number"
            min={0}
            defaultValue={row.quantity}
            className="form-control"
            onChange={(e) => {
              const updatedData = tableData.map((dataRow) => {
                if (dataRow === row) {
                  return { ...dataRow, quantity: e.target.value };
                }
                return dataRow;
              });
              setTableData(updatedData);
            }}
          />
        );
      },
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => {
        return (
          <input
            type="text"
            className="form-control"
            value={row.description}
            onChange={(e) => {
              const updatedData = tableData.map((dataRow) => {
                if (dataRow === row) {
                  return { ...dataRow, description: e.target.value };
                }
                return dataRow;
              });
              setTableData(updatedData);
            }}
          />
        );
      },
      width: "200px",
      sortable: true,
    },
    {
      name: "Price Level",
      selector: () => {
        return (
          <input
            type="text"
            defaultValue={"custom"}
            className="form-control"
            disabled
          />
        );
      },
      width: "110px",
    },
    {
      name: "Price",
      selector: (row) => {
        return (
          <input
            type="number"
            min={0}
            className="form-control"
            defaultValue={row.price}
            onChange={(e) => {
              const updatedData = tableData.map((dataRow) => {
                if (dataRow === row) {
                  return { ...dataRow, price: e.target.value };
                }
                return dataRow;
              });
              setTableData(updatedData);
            }}
          />
        );
      },
    },
    {
      name: "Delete",
      cell: (row) => (
        <button onClick={() => handleDelete(row)} className="delete-button">
          <IoCloseSharp fontSize={20} />
        </button>
      ),
      allowOverflow: true,
      button: true,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        //Header data
        const customers = await loadAndGetData("postObtainNetSuiteData", {
          companyId: companyId,
          endPoint: "obtainNetSuiteDataURL",
          typeOfConsult: 2,
        });

        const technicians = await loadAndGetData("postObtainNetSuiteData", {
          companyId: companyId,
          endPoint: "obtainNetSuiteDataURL",
          typeOfConsult: 8,
        });

        setCustomers(customers);
        setTechnicians(technicians);
      } catch (error) {
        throw error;
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchItems = async () => {
      if (selectedCustomer) {
        try {
          const subsidiaryId = customers.find(
            (customer) => customer.id === selectedCustomer
          ).subsidiaryId;

          const items = await loadAndGetData("postObtainNetSuiteData", {
            companyId: companyId,
            endPoint: "obtainNetSuiteDataURL",
            typeOfConsult: 4,
            subsidiaryId: subsidiaryId,
          });

          setItems(items);
        } catch (error) {
          throw error;
        }
      }
    };

    fetchItems();
  }, [selectedCustomer]);

  useEffect(() => {
    const fetchSerialNumbers = async () => {
      if (selectedItem) {
        //del item seleccionado obtener sus demas propiedades buscando en la lista
        //buscar por el uniqueId
        const item = items.find((item) => item.id === selectedItem);

        try {
          const serialNumbers = await loadAndGetData("postObtainNetSuiteData", {
            companyId: companyId,
            endPoint: "obtainNetSuiteDataURL",
            typeOfConsult: 5,
            itemId: selectedItem,
          });

          const updatedTableData = tableData.map((row) => {
            if (row.uniqueId === selectedItemUID) {
              return {
                ...row,
                description: item.description,
                serialNumbers,
              };
            }
            return row;
          });

          setTableData(updatedTableData);
        } catch (error) {
          throw error;
        }
      }
    };

    fetchSerialNumbers();
  }, [selectedItem, counter]);

  useEffect(() => {
    const fetchLocations = async () => {
      if (selectedCustomer) {
        try {
          const subsidiaryId = customers.find(
            (customer) => customer.id === selectedCustomer
          ).subsidiaryId;

          const locations = await loadAndGetData("postObtainNetSuiteData", {
            companyId: companyId,
            endPoint: "obtainNetSuiteDataURL",
            typeOfConsult: 9,
            subsidiaryId: subsidiaryId,
          });

          setLocations(locations);
        } catch (error) {
          throw error;
        }
      }
    };

    fetchLocations();
  }, [selectedCustomer]);

  const loadAndGetData = async (endpoint, body) => {
    try {
      const res = await requester.dynamicRequest(
        `${envUrl}/${endpoint}`,
        "POST",
        body
      );
      return res.responseService;
    } catch (error) {
      throw error;
    }
  };

  const onOpenModal = () => {
    setOpen(true);
    setSpinner(false);
  };

  const onCloseModal = () => {
    //reset all the values
    setTableData([]);
    setSelectedCustomer(null);
    setSelectedLocation("");
    setSelectedTechnicians([]);
    setOpen(false);
  };

  const handleAddNewItem = async () => {
    const subsidiaryId = selectedCustomer
      ? customers.find((customer) => customer.id === selectedCustomer)
          .subsidiaryId
      : null;

    if (subsidiaryId) {
      const newRow = {
        uniqueId: uuid(),
        item: "",
        serialNumber: "",
        quantity: 0,
        description: "",
        priceLevel: "custom",
        price: 0,
        serialNumbers: [],
      };
      setTableData([...tableData, newRow]);

      try {
        const items = await loadAndGetData("postObtainNetSuiteData", {
          companyId: companyId,
          endPoint: "obtainNetSuiteDataURL",
          typeOfConsult: 4,
          subsidiaryId: subsidiaryId,
        });

        setItems(items);
      } catch (error) {
        throw error;
      }
    } else {
      Swal.fire({
        icon: "warning",
        title: "You need to select a customer first!",
        timer: 2000,
      });
    }
  };

  const handleDelete = (row) => {
    const updatedData = tableData.filter((dataRow) => dataRow !== row);
    setTableData(updatedData);
  };

  const handleCreateParent = () => {
    if (!selectedCustomer || !selectedLocation) {
      let message = "";
      if (!selectedCustomer && !selectedLocation)
        message = "customer and a location";
      else if (!selectedCustomer) message = "customer";
      else message = "location";

      Swal.fire({
        icon: "warning",
        title: `You need to select a ${message}!`,
        timer: 2000,
      });
      return;
    }

    if (selectedTechnicians.length === 0) {
      Swal.fire({
        icon: "warning",
        title: "You need to select at least one technician!",
        timer: 2000,
      });
      return;
    }

    const today = moment().format("MM/DD/YYYY");

    const items = tableData.map((row) => ({
      itemId: parseFloat(row.item),
      qty: parseFloat(row.quantity),
      precisionAsset: row.serialNumber || "",
      rate: parseFloat(row.price) || 0,
      description: row.description || "",
    }));

    if (
      items.some(
        (item) =>
          item.itemId === "" ||
          item.precisionAsset === "" ||
          item.qty === "" ||
          item.description === ""
      )
    ) {
      Swal.fire({
        icon: "warning",
        title: "The item, serial, quantity and description are mandatory!",
        timer: 2000,
      });
      return;
    }

    const objToSend = {
      companyId: companyId,
      endPoint: "obtainNetSuiteDataURL",
      typeOfConsult: 6,
      customer: selectedCustomer,
      serviceDate: today,
      jobType: "2",
      technician: selectedTechnicians,
      location: selectedLocation,
      isParent: true,
      items: items,
    };

    setSpinner(true);

    requester
      .dynamicRequest(`${envUrl}/postObtainNetSuiteData`, "POST", objToSend)
      .then((res) => {
        if (res.responseService[0].message === "ERROR") {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: res.responseService[0].details,
            timer: 3000,
          });
          setSpinner(false);
          onSuccessParent({ success: false, parentCheckInName: "" });

          return;
        }

        onSuccessParent({
          success: true,
          parentCheckInName: res.responseService[0].parentCheckInName,
        });
        setSpinner(false);
        //reset all the values
        setTableData([]);
        setSelectedCustomer(null);
        setSelectedLocation("");
        setSelectedTechnicians([]);
        onCloseModal();
        return;
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: err,
          text: "Something went wrong!",
          timer: 3000,
        });
        onSuccessParent({ success: false, parentCheckInName: "" });
        setSpinner(false);
      });
  };

  const handleItemSelection = (row, e) => {
    const updatedData = tableData.map((dataRow) => {
      if (dataRow === row) {
        setSelectedItemUID(row.uniqueId);
        setSelectedItem(e.target.value);
        return { ...dataRow, item: e.target.value };
      }
      return dataRow;
    });
    setTableData(updatedData);
    setCounter((prevCounter) => prevCounter + 1);
  };

  return (
    <>
      <button
        type="button"
        className="available_button custom_width_parent_button"
        style={{
          marginBottom: "1rem",
          width: "100%",
          height: "max-content",
        }}
        onClick={onOpenModal}
      >
        Create Parent
      </button>

      <Modal
        open={open}
        center
        closeOnOverlayClick={false}
        classNames={{ modal: "custom-modal" }}
      >
        {spinner && <LoadingSpinner />}
        <div className="header_modal">
          <h2
            style={{
              marginRight: "1rem",
            }}
          >
            Parent Check - In
          </h2>
          <button
            onClick={onCloseModal}
            style={{
              cursor: "pointer",
            }}
          >
            <IoCloseSharp fontSize={25} />
          </button>
        </div>

        <div
          className="modal_body"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            margin: "1rem 0",
          }}
        >
          <div className="mb-3">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                defaultChecked
                disabled
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                Parent Check - In
              </label>
            </div>
          </div>
          <section className="container_flex_inputs">
            <div className="w-100">
              <label className="form-label">Customer</label>
              <Select
                options={customers.map((customer) => ({
                  value: customer.id,
                  label: customer.name,
                }))}
                onChange={(selectedOption) =>
                  setSelectedCustomer(selectedOption.value)
                }
              />
            </div>

            <div className="w-100">
              <label className="form-label">Location</label>
              <Select
                onChange={(selectedOption) =>
                  setSelectedLocation(selectedOption.value)
                }
                options={locations.map((location) => ({
                  value: location.locationId,
                  label: location.locationName,
                }))}
              />
            </div>
          </section>

          <div className="w-100">
            <label className="form-label">Technician</label>
            <select
              className="form-select"
              multiple
              value={selectedTechnicians}
              onChange={(e) =>
                setSelectedTechnicians(
                  Array.from(e.target.selectedOptions, (option) => option.value)
                )
              }
            >
              {technicians.map((technician) => (
                <option key={technician.id} value={technician.id}>
                  {technician.technician}
                </option>
              ))}
            </select>
          </div>

          <button
            type="button"
            className="btn_create_item"
            onClick={() => handleAddNewItem()}
          >
            Add Item
          </button>

          <DriversTable
            columns={columns}
            data={tableData}
            needSearch={false}
            needBanner={true}
            bannerText={"Items"}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <button
              type="button"
              className="available_button"
              onClick={() => handleCreateParent()}
            >
              Create Parent
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
